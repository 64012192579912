import React, { FC, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchProfileDataTC } from '../../../store/meddleware/profileMiddleware';
import './ViewProvile.scss';

interface IViewProfile {}

export const ViewProfile: FC<IViewProfile> = () => {
  const dispatch = useAppDispatch();

  const profileData = useAppSelector(state => state.profileReducer.profileData);

  useEffect(() => {
    dispatch(fetchProfileDataTC());
  }, []);

  return (
    <div className="admin__view-profile">
      <table className="admin__view-profile__table">
        <tbody className="admin__view-profile__table__tbody">
          <tr className="admin__view-profile__table__tbody__tr">
            <td className="td td-title">Username:</td>
            <td className="td td-value">{profileData.username}</td>
          </tr>
          <tr className="admin__view-profile__table__tbody__tr">
            <td className="td td-title">Tarif:</td>
            <td className="td td-value">{profileData.tariff}</td>
          </tr>
          <tr className="admin__view-profile__table__tbody__tr">
            <td className="td td-title">Phone:</td>
            <td className="td td-value">{profileData.phone}</td>
          </tr>
          <tr className="admin__view-profile__table__tbody__tr">
            <td className="td td-title">Email:</td>
            <td className="td td-value">{profileData.email}</td>
          </tr>
          <tr className="admin__view-profile__table__tbody__tr">
            <td className="td td-title">Company:</td>
            <td className="td td-value">{profileData.company_name}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
