import React, { FC, useEffect } from 'react';

import './Impressum.scss';
import { Spin } from 'antd';
import parse from 'html-react-parser';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { fetchPage } from '../../store/meddleware/pageMiddleware';

const formatHtmlString = (string: string): string => {
  let htmlString = string;
  // Removing extra escape characters
  htmlString = htmlString.replace(/\\n/g, '\n').replace(/\\r/g, '\r');

  // Removing double slashes
  htmlString = htmlString.replace(/\\\\/g, '\\');

  htmlString = htmlString.replace(/TMG\s/g, `TMG&nbsp;`);
  htmlString = htmlString.replace(/12053/g, `12053 &nbsp;`);
  htmlString = htmlString.replace(/Tel.:/g, ``);
  htmlString = htmlString.replace(/030/g, ``);
  htmlString = htmlString.replace(/href=\\/g, `href=`);

  return htmlString;
};

export const Impressum: FC = () => {
  const dispatch = useAppDispatch();

  const page = useAppSelector(state => state.pageReducer.page);
  const isLoading = useAppSelector(state => state.appReducer.isLoading);
  useEffect(() => {
    dispatch(fetchPage('/impressum/?format=json'));
  }, [dispatch]);
  return (
    <section className="impressum">
      <Spin className={`${isLoading ? 'preloader-active' : 'preloader'}`} size="large" />
      <div className="impressum__wrap">
        {page.text && (
          <>
            <h2 className="impressum__wrap__title">Impressum</h2>
            <div className="impressum__wrap__content">
              {parse(formatHtmlString(page.text))}
            </div>
          </>
        )}
      </div>
    </section>
  );
};
