import { createSlice } from '@reduxjs/toolkit';

import { IInstitutionResponseData, IProfileInstitution } from '../api/institution-api';

import {
  createInstitutionTC,
  editInstitutionTC,
  fetchProfileInstitutionsTC,
  fetchProfileInstitutionTC,
  removeInstitutionTC,
} from './meddleware/institutionMiddleware';

interface IInstitutionState {
  currentInstitution: IProfileInstitution | null;
  institutions: IInstitutionResponseData;
}

const initialState: IInstitutionState = {
  currentInstitution: null,
  institutions: {} as IInstitutionResponseData,
};

export const institutionSlice = createSlice({
  name: 'institution',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchProfileInstitutionsTC.fulfilled, (state, action) => {
      state.institutions = action.payload;
    });
    builder.addCase(fetchProfileInstitutionTC.fulfilled, (state, action) => {
      state.currentInstitution = action.payload;
    });
    builder.addCase(createInstitutionTC.fulfilled, (state, action) => {
      if (action.payload) {
        state.institutions.results.push(action.payload);
      }
    });
    builder.addCase(editInstitutionTC.fulfilled, (state, action) => {
      if (action.payload) {
        const index = state.institutions.results.findIndex(
          inst => inst.id === action.payload.id,
        );
        state.institutions.results[index] = action.payload;
      }
    });
    builder.addCase(removeInstitutionTC.fulfilled, (state, action) => {
      const index = state.institutions.results.findIndex(
        inst => inst.id === action.payload,
      );
      if (index > -1) {
        state.institutions.results.splice(index, 1);
      }
    });
  },
});

export const institutionReducer = institutionSlice.reducer;
