import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { profileApi } from '../../api/profile-api';
import { removeToken } from '../../utils/localStorage';
import { setIsLoggedInAC } from '../AuthSlice';

export const fetchProfileDataTC = createAsyncThunk(
  'profile/fetchProfileData',
  async (_, thunkAPI) => {
    try {
      const res = await profileApi.getProfileData();
      return res.data;
    } catch (e) {
      const error = e as AxiosError;
      const NotAuthStatus = 401;
      if (error.response?.status === NotAuthStatus) {
        thunkAPI.dispatch(setIsLoggedInAC({ isLoggedIn: false }));
        removeToken();
      }
      return thunkAPI.rejectWithValue(e);
    }
  },
);
