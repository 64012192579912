import React, { FC } from 'react';

import { NavLink } from 'react-router-dom';

import { ICategory } from '../../../../../api/categories-api';
import { ICompaniesResponse } from '../../../../../api/companies-api';
import arrowImg from '../../../../../assets/img/svg/circle-arrow-right-solid-white.svg';
import { PATH } from '../../../../../routes/Routes';

interface ICategoryItemBlock {
  categoryId: number;
  categories: ICategory[];
  companies: ICompaniesResponse | undefined;
}

export const CategoryItemBlock: FC<ICategoryItemBlock> = ({
  categoryId,
  categories,
  companies,
}) => {
  const currCategory = categories.find(cat => cat.id === categoryId);

  return (
    <div className="category-block__wrap__category">
      <div className="category-block__wrap__category__header">
        <NavLink
          className="category-block__wrap__category__header__top-img"
          to={`${PATH.HOME}${currCategory && currCategory.slug}/`}
        >
          <img src={currCategory && currCategory.icon} alt="icon" />
        </NavLink>
        <NavLink
          className="category-block__wrap__category__header__top-title"
          to={`${PATH.HOME}${currCategory && currCategory.slug}/`}
        >
          <span className="category-block__wrap__category__header__top-title__name">
            {currCategory && currCategory.name}
          </span>
          <span className="category-block__wrap__category__header__top-title__count">
            {/* {currCategory} */}
          </span>
        </NavLink>
      </div>
      <ul className="category-block__wrap__category__list">
        {companies?.results &&
          companies?.results.map(company => (
            <li key={company.id} className="category-block__wrap__category__list__item">
              <NavLink
                className="category-block__wrap__category__list__item__link"
                to={`${PATH.COMPANY_ITEM}${company.slug}/`}
              >
                {company.name}
              </NavLink>
            </li>
          ))}
      </ul>
      <NavLink
        className="category-block__wrap__category__bottom-link"
        to={`${PATH.HOME}${currCategory && currCategory.slug}/`}
      >
        <img src={arrowImg} alt="alle" />
        Alle
      </NavLink>
    </div>
  );
};
