import { AxiosResponse } from 'axios';

import { instance } from './http';

export const regionsApi = {
  getRegionsList(region?: string) {
    return instance.get<IRegionsResponse, AxiosResponse<IRegionsResponse>>(
      `api/region/?region=${region}`,
    );
  },
};

export interface IRegion {
  region: string;
}

export interface IRegionsResponse {
  count: number;
  links: {
    next: string | null;
    prev: string | null;
  };
  num_pages: number;
  current_page: number;
  results: IRegion[];
}
