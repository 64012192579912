import React, { FC, useEffect, useState } from 'react';

import { Input } from 'antd';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { PATH } from '../../../routes/Routes';
import { setAppErrorAC } from '../../../store/AppSlice';
import { sendMessage } from '../../../store/meddleware/messageMiddleware';

interface IOrderFormData {
  company: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}

export const FORM_ORDER_DATA = {
  first_name: {
    title: 'Vorname',
  },
  last_name: {
    title: 'Nachname',
  },
  email: {
    title: 'E-Mail',
  },
  phone: {
    title: 'Telefon',
  },
  subject: {
    title: 'Thema',
  },
  message: {
    title: 'Botschaft',
  },
};

export const OrderForm: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const companyId = params.id;

  const error = useAppSelector(state => state.appReducer.error);

  const [isSendData, setIsSendData] = useState<boolean>(false);

  useEffect(
    () => () => {
      dispatch(setAppErrorAC({ error: null }));
      setIsSendData(false);
    },
    [],
  );

  const { handleSubmit, control } = useForm<IOrderFormData>();
  const onSubmit: SubmitHandler<IOrderFormData> = data => {
    if (companyId) {
      const newData = {
        company: +companyId,
        // company: 515,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: data.phone,
        subject: data.subject,
        message: data.message,
      };
      dispatch(sendMessage(newData));
    }
    setIsSendData(true);
  };

  if (!error && isSendData) {
    return <Navigate to={PATH.HOME} />;
  }

  return (
    <div>
      <form
        className="root__order__wrap__left__order-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="root__order__wrap__left__order-form__fields-block">
          <h3 className="category-title">Persönliche Informationen</h3>
          <div className="category">
            <div className="root__order__wrap__left__order-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="first_name"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="first_name" className="label">
                    {FORM_ORDER_DATA.first_name.title}
                    <Input
                      id="first_name"
                      className="field"
                      type="text"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>

            <div className="root__order__wrap__left__order-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="last_name"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="last_name" className="label">
                    {FORM_ORDER_DATA.last_name.title}
                    <Input
                      id="last_name"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>
          </div>

          <h3 className="category-title">Kontakt</h3>
          <div className="category">
            <div className="root__order__wrap__left__order-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="email" className="label">
                    {FORM_ORDER_DATA.email.title}
                    <Input
                      id="email"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>

            <div className="root__order__wrap__left__order-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="phone" className="label">
                    {FORM_ORDER_DATA.phone.title}
                    <Input
                      id="phone"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>
          </div>

          <h3 className="category-title">Einzelheiten</h3>
          <div className="category">
            <div className="root__order__wrap__left__order-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="subject"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="subject" className="label">
                    {FORM_ORDER_DATA.subject.title}
                    <Input
                      id="subject"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>

            <div className="root__order__wrap__left__order-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="message"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="message" className="label">
                    {FORM_ORDER_DATA.message.title}
                    <Input
                      id="message"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>
          </div>
        </div>

        <div className="root__order__wrap__left__order-form__btn-wrap">
          <input className="btn-submit" type="submit" value="Sichern" />
        </div>
      </form>
    </div>
  );
};
