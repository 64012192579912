import { createSlice } from '@reduxjs/toolkit';

import { IProfileData } from '../api/profile-api';

import { fetchProfileDataTC } from './meddleware/profileMiddleware';

interface IProfileState {
  profileData: IProfileData;
}

const initialState: IProfileState = {
  profileData: {} as IProfileData,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchProfileDataTC.fulfilled, (state, action) => {
      state.profileData = action.payload;
    });
  },
});

export const profileReducer = profileSlice.reducer;
