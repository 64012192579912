import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { IMessage, messageApi } from '../../api/message-api';
import { setAppErrorAC } from '../AppSlice';

export const sendMessage = createAsyncThunk(
  'companies/sendMessage',
  async (data: IMessage, thunkAPI) => {
    try {
      const res = await messageApi.sendMessage(data);
      return res.data;
    } catch (e) {
      const error = e as AxiosError;
      thunkAPI.dispatch(setAppErrorAC({ error: error.message }));
      return thunkAPI.rejectWithValue(e);
    }
  },
);
