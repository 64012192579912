import React, { FC, useEffect, useState } from 'react';

import './CompanyListPage.scss';

import { Empty, Spin } from 'antd';

import { IBanner } from '../../api/layout-api';
import { ISearchCompany } from '../../api/search-api';
import { CompanyComponentForList } from '../../components/CompanyComponentForList/CompanyComponentForList';
import { Pagination } from '../../components/CompanyComponentForList/Pagination/Pagination';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setSearchQueryParams } from '../../store/CompaniesSlice';
import { fetchCategories } from '../../store/meddleware/categoriesMiddleware';
import {
  fetchNextOrPrevListOfCompanies,
  fetchSearchCompanies,
} from '../../store/meddleware/companiesMiddleware';
import { fetchLayout } from '../../store/meddleware/layoutMiddleware';
import { executeScroll } from '../../utils/executeScroll';
import { getRandomItem } from '../../utils/getRandomItem';
import { ListsFilter } from '../Forms/ListsFilter/ListsFilter';

interface ICompanyListPage {
  categoryId: number;
  companies: ISearchCompany[];
  companiesNumPages: number;
  companiesCurrentPage: number;
  nextLink: string | null;
  prevLink: string | null;
  currentRegion: string | null;
}

export const CompanyListPage: FC<ICompanyListPage> = ({
  categoryId,
  companies,
  companiesNumPages,
  companiesCurrentPage,
  nextLink,
  prevLink,
  currentRegion,
}) => {
  const dispatch = useAppDispatch();

  // const companies = useAppSelector(
  //   state => state.companiesReducer.searchCompaniesList?.results,
  // );
  // const healthCareCompanies = useAppSelector(
  //   state => state.companiesReducer.searchCompaniesList.health_care?.results,
  // );
  // const nursingHomesCompanies = useAppSelector(
  //   state => state.companiesReducer.searchCompaniesList.nursing_homes?.results,
  // );
  // const pharmaciesCompanies = useAppSelector(
  //   state => state.companiesReducer.searchCompaniesList.pharmacies?.results,
  // );
  // const suppliesCompanies = useAppSelector(
  //   state => state.companiesReducer.searchCompaniesList.supplies?.results,
  // );
  const categories = useAppSelector(state => state.categoriesReducer.categories?.results);
  const searchQueryParams = useAppSelector(
    state => state.companiesReducer.searchQueryParams,
  );
  const layoutsList = useAppSelector(state => state.layoutReducer.layout?.results);
  const isLoading = useAppSelector(state => state.appReducer.isLoading);
  // const companiesNumPages = useAppSelector(
  //   state => state.companiesReducer.searchCompaniesList?.num_pages,
  // );
  // const companiesCurrentPage = useAppSelector(
  //   state => state.companiesReducer.searchCompaniesList?.current_page,
  // );
  // const nextLink = useAppSelector(
  //   state => state.companiesReducer.searchCompaniesList?.links?.next,
  // );
  // const prevLink = useAppSelector(
  //   state => state.companiesReducer.searchCompaniesList?.links?.prev,
  // );

  const [currentUnternehmenBanner, setCurrentUnternehmenBanner] = useState<IBanner>();

  const CURR_CATEGORY_ID = categoryId;
  const INDEX_UNTERNEHMEN_BIG = layoutsList?.find(
    layout => layout.slug === 'index-unterhenmen-big',
  );

  const currentCategory = categories?.find(cat => cat.id === CURR_CATEGORY_ID);

  useEffect(() => {
    executeScroll();
  }, []);

  useEffect(() => {
    if (!companies?.length) {
      if (searchQueryParams) {
        dispatch(
          fetchSearchCompanies({
            categoryId: searchQueryParams.categoryId,
            region: searchQueryParams.region,
          }),
        );
        dispatch(setSearchQueryParams(null));
      } else {
        dispatch(fetchSearchCompanies({ categoryId: `${CURR_CATEGORY_ID}` }));
      }
    }

    return () => {
      dispatch(setSearchQueryParams(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!categories) {
      dispatch(fetchCategories());
    }
  }, []);

  useEffect(() => {
    if (!layoutsList) {
      dispatch(fetchLayout());
    }
  }, []);

  useEffect(() => {
    if (INDEX_UNTERNEHMEN_BIG) {
      setCurrentUnternehmenBanner(
        getRandomItem(INDEX_UNTERNEHMEN_BIG?.banners) as IBanner,
      );
    }
  }, [layoutsList]);

  const searchCompanyOfRegion = (region: string): void => {
    dispatch(fetchSearchCompanies({ categoryId: `${CURR_CATEGORY_ID}`, region }));
  };

  const getNextListOfCompanies = (): void => {
    if (nextLink) {
      dispatch(fetchNextOrPrevListOfCompanies({ link: nextLink, categoryId }));
    }
  };
  const getPrevListOfCompanies = (): void => {
    if (prevLink) {
      dispatch(fetchNextOrPrevListOfCompanies({ link: prevLink, categoryId }));
    }
  };

  return (
    <div className="company-list-page">
      <div className="company-list-page__list">
        <ListsFilter
          searchCallback={searchCompanyOfRegion}
          currentRegion={currentRegion}
          categoryId={categoryId}
        />

        {companies &&
          companies.map(company => (
            <CompanyComponentForList
              key={company.id}
              company={company}
              currentCategory={currentCategory}
            />
          ))}

        {!companies?.length && !isLoading && (
          <Empty
            className="empty-data"
            description="No data"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}

        {companiesNumPages && nextLink && (
          <Pagination
            previous={prevLink}
            next={nextLink}
            currentPage={companiesCurrentPage}
            countPage={companiesNumPages}
            previousCallback={getPrevListOfCompanies}
            nextCallback={getNextListOfCompanies}
          />
        )}
        <Spin
          className={`${isLoading ? 'preloader-active' : 'preloader'}`}
          size="large"
        />
      </div>
      <div className="company-list-page__advertising-block">
        <a href={currentUnternehmenBanner?.url}>
          <img
            className="company-list-page__advertising-block__img"
            src={currentUnternehmenBanner?.image}
            alt={currentUnternehmenBanner?.title}
          />
        </a>
      </div>
    </div>
  );
};
