import React, { FC, useEffect, useState } from 'react';

import { Modal } from 'antd';
import { NavLink } from 'react-router-dom';

import editImg from '../../../assets/img/svg/pen-to-square-solid.svg';
import removeImg from '../../../assets/img/svg/trash-can-solid.svg';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { PATH } from '../../../routes/Routes';
import {
  fetchProfileInstitutionsTC,
  removeInstitutionTC,
} from '../../../store/meddleware/institutionMiddleware';

import './ProfileCompanies.scss';

interface IProfileCompanies {}

export const ProfileCompanies: FC<IProfileCompanies> = () => {
  const dispatch = useAppDispatch();

  const institutions = useAppSelector(state => state.institutionReducer.institutions);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [selectedInstitution, setSelectedInstitution] = useState<number | null>(null);

  useEffect(() => {
    dispatch(fetchProfileInstitutionsTC());
  }, [institutions.count]);

  const removeInstitution = (institutionId: number): void => {
    dispatch(removeInstitutionTC(institutionId));
    setSelectedInstitution(null);
    setIsOpenModal(false);
  };

  const changeModalState = (): void => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <div className="admin-page__companies">
      <table className="admin-page__companies__table">
        <thead className="admin-page__companies__table__thead">
          <tr className="admin-page__companies__table__thead__tr">
            <th>Company name</th>
            <th>Company phone</th>
            <th>Company adress</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="admin-page__companies__table__tbody">
          {institutions &&
            institutions.results?.map(institution => (
              <tr
                className="admin-page__companies__table__tbody__tr"
                key={institution.id}
              >
                <td className="td company-name">{institution.name}</td>
                <td className="td">{institution.phone}</td>
                <td className="td">
                  {institution.street}, {institution.region}
                </td>
                <td className="td td-option">
                  <NavLink
                    to={`${PATH.INSTITUTION_FORM}${institution.id}/`}
                    className="edit-link"
                    role="presentation"
                  >
                    <img src={editImg} alt="delete" />
                  </NavLink>
                  <span
                    className="remove-link"
                    role="presentation"
                    onClick={() => {
                      changeModalState();
                      setSelectedInstitution(institution.id);
                    }}
                  >
                    <img src={removeImg} alt="delete" />
                  </span>
                </td>
                <Modal
                  title="Institution entfernen?"
                  open={isOpenModal}
                  onOk={() => {
                    if (selectedInstitution) {
                      removeInstitution(selectedInstitution);
                    }
                  }}
                  onCancel={changeModalState}
                />
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
