import { createAsyncThunk } from '@reduxjs/toolkit';

import { authAPI } from '../../api/auth-api';
import { ILoginParams, IRegistrationData } from '../../api/authService';
import { loadToken, removeToken, saveToken } from '../../utils/localStorage';
import { setIsLoggedInAC } from '../AuthSlice';

export const loginTC = createAsyncThunk(
  'auth/login',
  async (param: ILoginParams, thunkAPI) => {
    try {
      const res = await authAPI.login(param);
      saveToken(res.data.access);
      return { isLoggedIn: true };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const verifyTC = createAsyncThunk('auth/verify', async (_, thunkAPI) => {
  try {
    const tokenLS = loadToken();
    await authAPI.verify({ token: tokenLS });

    return { isLoggedIn: true };
  } catch (e) {
    thunkAPI.dispatch(setIsLoggedInAC({ isLoggedIn: false }));
    removeToken();
    return thunkAPI.rejectWithValue(e);
  }
});

export const registrationTC = createAsyncThunk(
  'auth/registration',
  async (param: IRegistrationData, thunkAPI) => {
    try {
      await authAPI.registration(param);

      return { isLoggedIn: false };
    } catch (e) {
      thunkAPI.dispatch(setIsLoggedInAC({ isLoggedIn: false }));
      return thunkAPI.rejectWithValue(e);
    }
  },
);
