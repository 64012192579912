import { createSlice } from '@reduxjs/toolkit';

import { IBannerResponseData, IProfileBanner } from '../api/banner-api';

import {
  createBannerTC,
  editBannerTC,
  fetchProfileBannersTC,
  fetchProfileBannerTC,
  removeBannerTC,
} from './meddleware/bannerMiddleware';

interface IBannerState {
  currentBanner: IProfileBanner | null;
  banners: IBannerResponseData;
}

const initialState: IBannerState = {
  currentBanner: null,
  banners: {} as IBannerResponseData,
};

export const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchProfileBannersTC.fulfilled, (state, action) => {
      state.banners = action.payload;
    });
    builder.addCase(fetchProfileBannerTC.fulfilled, (state, action) => {
      state.currentBanner = action.payload;
    });
    builder.addCase(createBannerTC.fulfilled, (state, action) => {
      if (action.payload) {
        state.banners.results.push(action.payload);
      }
    });
    builder.addCase(editBannerTC.fulfilled, (state, action) => {
      if (action.payload) {
        const index = state.banners.results.findIndex(
          banner => banner.id === action.payload.id,
        );
        state.banners.results[index] = action.payload;
      }
    });
    builder.addCase(removeBannerTC.fulfilled, (state, action) => {
      const index = state.banners.results.findIndex(ban => ban.id === action.payload);
      if (index > -1) {
        state.banners.results.splice(index, 1);
      }
    });
  },
});

export const bannerReducer = bannerSlice.reducer;
