import axios, { AxiosResponse } from 'axios';

import { instance } from './http';

export const searchApi = {
  getSearchList(queryString: string) {
    return instance.get<
      ICompaniesSearchResponse,
      AxiosResponse<ICompaniesSearchResponse>
    >(`api/search/${queryString}`);
  },
  getNextOrPrevListOfCompanies(link: string) {
    return axios.get<ICompaniesSearchResponse, AxiosResponse<ICompaniesSearchResponse>>(
      `${link}`,
    );
  },
};

export interface ISearchCompany {
  // categories: number[];
  description: string;
  id: number;
  // logo: string | null;
  name: string;
  region: string;
  slug: string;
  street: string;
  email: string | null;
  phone: string | null;
  fax: string | null;
  www: string | null;
  zip: string | null;
}

export interface ICompaniesSearchResponse {
  count: number;
  links: {
    next: string | null;
    prev: string | null;
  };
  num_pages: number;
  current_page: number;
  results: ISearchCompany[];
}
