import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICompaniesResponse, ICompany } from '../api/companies-api';
import { ICompaniesSearchResponse } from '../api/search-api';

import {
  fetchCompaniesByCategory,
  fetchCompanyDescription,
  fetchNextOrPrevListOfCompanies,
  fetchSearchCompanies,
} from './meddleware/companiesMiddleware';

interface ICompaniesState {
  // searchCompaniesList: ICompaniesSearchResponse;
  searchCompaniesList: {
    health_care: ICompaniesSearchResponse;
    nursing_homes: ICompaniesSearchResponse;
    pharmacies: ICompaniesSearchResponse;
    supplies: ICompaniesSearchResponse;
  };
  currentCompany: ICompany;
  companiesByCategory: {
    health_care: ICompaniesResponse;
    nursing_homes: ICompaniesResponse;
    pharmacies: ICompaniesResponse;
    supplies: ICompaniesResponse;
  };
  currentRegion: {
    health_care: string | null;
    nursing_homes: string | null;
    pharmacies: string | null;
    supplies: string | null;
  };
  searchQueryParams: { categoryId: string; region?: string } | null;
}

const initialState: ICompaniesState = {
  // searchCompaniesList: {} as ICompaniesSearchResponse,
  searchCompaniesList: {
    health_care: {} as ICompaniesSearchResponse,
    nursing_homes: {} as ICompaniesSearchResponse,
    pharmacies: {} as ICompaniesSearchResponse,
    supplies: {} as ICompaniesSearchResponse,
  },
  currentCompany: {} as ICompany,
  companiesByCategory: {
    health_care: {} as ICompaniesResponse,
    nursing_homes: {} as ICompaniesResponse,
    pharmacies: {} as ICompaniesResponse,
    supplies: {} as ICompaniesResponse,
  },
  currentRegion: {
    health_care: null,
    nursing_homes: null,
    pharmacies: null,
    supplies: null,
  },
  searchQueryParams: null,
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setSearchQueryParams(
      state,
      action: PayloadAction<{ categoryId: string; region?: string } | null>,
    ) {
      state.searchQueryParams = action.payload;
    },
    setCurrentRegion(
      state,
      action: PayloadAction<{
        categoryId: string;
        region: string | undefined | null;
      } | null>,
    ) {
      const healthCareCategoryId = 5;
      const nursingHomesCategoryId = 6;
      const pharmaciesCategoryId = 7;
      const suppliesCategoryId = 8;
      if (action.payload?.categoryId === healthCareCategoryId.toString()) {
        if (action.payload.region) {
          state.currentRegion.health_care = action.payload?.region;
        } else {
          state.currentRegion.health_care = null;
        }
      }
      if (action.payload?.categoryId === nursingHomesCategoryId.toString()) {
        if (action.payload.region) {
          state.currentRegion.nursing_homes = action.payload.region;
        } else {
          state.currentRegion.nursing_homes = null;
        }
      }
      if (action.payload?.categoryId === pharmaciesCategoryId.toString()) {
        if (action.payload.region) {
          state.currentRegion.pharmacies = action.payload.region;
        } else {
          state.currentRegion.pharmacies = null;
        }
      }
      if (action.payload?.categoryId === suppliesCategoryId.toString()) {
        if (action.payload.region) {
          state.currentRegion.supplies = action.payload.region;
        } else {
          state.currentRegion.supplies = null;
        }
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSearchCompanies.fulfilled, (state, action) => {
      // state.searchCompaniesList = action.payload;
      const healthCareCategoryId = 5;
      const nursingHomesCategoryId = 6;
      const pharmaciesCategoryId = 7;
      const suppliesCategoryId = 8;
      if (action.payload.category === healthCareCategoryId.toString()) {
        state.searchCompaniesList.health_care = action.payload.data;
      }
      if (action.payload.category === nursingHomesCategoryId.toString()) {
        state.searchCompaniesList.nursing_homes = action.payload.data;
      }
      if (action.payload.category === pharmaciesCategoryId.toString()) {
        state.searchCompaniesList.pharmacies = action.payload.data;
      }
      if (action.payload.category === suppliesCategoryId.toString()) {
        state.searchCompaniesList.supplies = action.payload.data;
      }
    });
    builder.addCase(fetchCompanyDescription.fulfilled, (state, action) => {
      state.currentCompany = action.payload;
    });
    builder.addCase(fetchCompaniesByCategory.fulfilled, (state, action) => {
      if (action.payload.results[0].categories[0] === 5) {
        state.companiesByCategory.health_care = action.payload;
      }
      if (action.payload.results[0].categories[0] === 6) {
        state.companiesByCategory.nursing_homes = action.payload;
      }
      if (action.payload.results[0].categories[0] === 7) {
        state.companiesByCategory.pharmacies = action.payload;
      }
      if (action.payload.results[0].categories[0] === 8) {
        state.companiesByCategory.supplies = action.payload;
      }
    });
    builder.addCase(fetchNextOrPrevListOfCompanies.fulfilled, (state, action) => {
      // state.searchCompaniesList = action.payload;
      const healthCareCategoryId = 5;
      const nursingHomesCategoryId = 6;
      const pharmaciesCategoryId = 7;
      const suppliesCategoryId = 8;
      if (action.payload.categoryId === healthCareCategoryId) {
        state.searchCompaniesList.health_care = action.payload.data;
      }
      if (action.payload.categoryId === nursingHomesCategoryId) {
        state.searchCompaniesList.nursing_homes = action.payload.data;
      }
      if (action.payload.categoryId === pharmaciesCategoryId) {
        state.searchCompaniesList.pharmacies = action.payload.data;
      }
      if (action.payload.categoryId === suppliesCategoryId) {
        state.searchCompaniesList.supplies = action.payload.data;
      }
    });
  },
});

export const companiesReducer = companiesSlice.reducer;
export const { setSearchQueryParams, setCurrentRegion } = companiesSlice.actions;
