import React, { FC, memo } from 'react';

import './TextContainer.scss';
import { IBanner } from '../../../../api/layout-api';

interface ITextContainer {
  banners: {
    currentUnternehmenBanner1: IBanner | undefined;
    currentUnternehmenBanner2: IBanner | undefined;
  };
}

export const TextContainer: FC<ITextContainer> = memo(({ banners }) => (
  <section className="main-text-container">
    <div className="main-text-container__wrap">
      <div className="main-text-container__wrap__text-block">
        <h3>Über dieses Angebot</h3>
        <p>
          Die richtige Auswahl einer Pflegeeinrichtung - Das sollten Sie beachten. Auch
          über die Qualität der einzelnen Einrichtungen informiert der Pflegeraiting auf
          Grundlage objektiver Prüfergebnisse. Außerdem haben Sie die Möglichkeit,
          verschiedene Pflegeeinrichtungen miteinander zu vergleichen. Auswahl
          Pflegedienst: Es ist wichtig, den richtigen Pflegedienst zu finden, der am
          besten Ihren Anforderungen passt. Im fortgeschrittenen Alter kann der Bedarf
          nach Unterstützung im Alltag wachsen und irgendwann wird häufig eine
          Alters-Pflege notwendig. Für Angehörige ist diese Pflege mit einem großen
          Aufwand verbunden und in vielen Fällen nicht realisierbar. Hier kommen ambulante
          Pflegedienste ins Spiel, die die tägliche Pflege von pflegebedürftigen Personen
          jeden Alters übernehmen. Doch bei der Auswahl eines Pflegedienstes ist einiges
          zu beachten.
        </p>
        <p>
          Bevor ein Pflegedienst für die häusliche Pflege beauftragt wird, müssen Sie sich
          überlegen, welche Anforderungen Sie an einen mobilen Pflegedienst stellen bzw.
          welche Aufgaben der häusliche Pflegedienst erfüllen soll. Nicht jeder
          Pflegedienst übernimmt alle Aufgaben. Viele Pflegedienste haben zum Beispiel
          kein Fach-Personal um eine ambulante psychiatrische Pflege, eine
          Kinderkrankenpflege (Kinderpflegedienst) oder eine spezialisierte ambulante
          Palliativversorgung durchzuführen. In der Regel ist die richtige
          Pflegedienstauswahl eine langfristige Entscheidung. Sie entscheiden, von wem Sie
          die nächsten Jahre gepflegt und betreut werden.
        </p>
      </div>
      <div className="main-text-container__wrap__advertising-block">
        <div className="main-text-container__wrap__advertising-block__item">
          <a href={banners?.currentUnternehmenBanner1?.url}>
            <img
              className="main-text-container__wrap__advertising-block__item__img"
              src={banners.currentUnternehmenBanner1?.image}
              alt={banners.currentUnternehmenBanner1?.title}
            />
          </a>
        </div>
        <div className="main-text-container__wrap__advertising-block__item">
          <a href={banners?.currentUnternehmenBanner2?.url}>
            <img
              className="main-text-container__wrap__advertising-block__item__img"
              src={banners.currentUnternehmenBanner2?.image}
              alt={banners.currentUnternehmenBanner2?.title}
            />
          </a>
        </div>
      </div>
    </div>
  </section>
));
