import React, { FC } from 'react';
import './LoginForm.scss';

import { Input } from 'antd';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';

import { ILoginParams } from '../../../api/authService';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { PATH } from '../../../routes/Routes';
import { loginTC } from '../../../store/meddleware/authMiddleware';

export const LoginForm: FC = () => {
  const dispatch = useAppDispatch();

  const isLoggedIn = useAppSelector(state => state.authReducer.isLoggedIn);

  const { handleSubmit, control } = useForm<ILoginParams>();
  const onSubmit: SubmitHandler<ILoginParams> = data => {
    dispatch(loginTC(data));
  };

  if (isLoggedIn) {
    return <Navigate to={PATH.PROFILE} />;
  }

  return (
    <section className="root__login">
      <form className="root__login__login-form" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="root__login__login-form__title">ANMELDEN</h1>
        <div className="root__login__login-form__fields-block">
          <div className="root__login__login-form__fields-block__field-wrap">
            <Controller
              control={control}
              name="username"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="username" className="label">
                  Login or E-Mail
                  <Input
                    id="username"
                    className="field"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="root__login__login-form__fields-block__field-wrap">
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="password" className="label">
                  Passwort
                  <Input.Password
                    id="password"
                    className="field"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>
        </div>

        <div className="root__login__login-form__btn-wrap">
          <input className="btn-submit" type="submit" value="Anmelden" />
        </div>
      </form>
    </section>
  );
};
