import { createAsyncThunk } from '@reduxjs/toolkit';

import { companiesApi } from '../../api/companies-api';
import { searchApi } from '../../api/search-api';
import { setLoadingStatusAC } from '../AppSlice';
import { setCurrentRegion } from '../CompaniesSlice';

export const fetchSearchCompanies = createAsyncThunk(
  'companies/fetchSearchCompanies',
  async (payload: { categoryId: string; region?: string }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingStatusAC({ status: true }));
    try {
      const param = payload.region
        ? `?category=${payload.categoryId}&region=${payload.region}`
        : `?category=${payload.categoryId}`;
      const res = await searchApi.getSearchList(param);
      thunkAPI.dispatch(
        setCurrentRegion({ categoryId: payload.categoryId, region: payload.region }),
      );
      return { category: payload.categoryId, data: res.data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    } finally {
      thunkAPI.dispatch(setLoadingStatusAC({ status: false }));
    }
  },
);

export const fetchCompanyDescription = createAsyncThunk(
  'companies/fetchCompanyDescription',
  async (slug: string, thunkAPI) => {
    thunkAPI.dispatch(setLoadingStatusAC({ status: true }));
    try {
      const res = await companiesApi.getCompanyDescription(slug);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    } finally {
      thunkAPI.dispatch(setLoadingStatusAC({ status: false }));
    }
  },
);

export const fetchCompaniesByCategory = createAsyncThunk(
  'companies/fetchCompanies',
  async (categoryId: string, thunkAPI) => {
    try {
      const res = await companiesApi.getCompaniesList(categoryId);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchNextOrPrevListOfCompanies = createAsyncThunk(
  'companies/fetchNextOrPrevListOfCompanies',
  async (payload: { link: string; categoryId: number }, thunkAPI) => {
    try {
      const res = await searchApi.getNextOrPrevListOfCompanies(payload.link);
      return { data: res.data, categoryId: payload.categoryId };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);
