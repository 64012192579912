import React, { FC, memo } from 'react';

import './AdvertisingListContainer.scss';
import { LayoutItem } from '../../../../api/layout-api';

interface IAdvertisingListContainer {
  banners: LayoutItem | undefined;
}

export const AdvertisingListContainer: FC<IAdvertisingListContainer> = memo(
  ({ banners }) => (
    <section className="main-advertising-container">
      <h2 className="main-advertising-container__title">Top unternehmen</h2>
      <div className="main-advertising-container__wrap">
        {banners &&
          banners?.banners?.map(banner => (
            <a href={banner.url} key={banner.id}>
              <img
                className="main-advertising-container__wrap__img"
                src={banner.image}
                alt={banner.title}
              />
            </a>
          ))}
      </div>
    </section>
  ),
);
