import React, { FC, useEffect, useMemo, useState } from 'react';
import './BannerForm.scss';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Select, Upload, UploadFile } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';

import { BANNER_FORM_DATA, IBannerFormData } from '../../../../api/banner-api';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { PATH } from '../../../../routes/Routes';
import { setAppErrorAC } from '../../../../store/AppSlice';
import {
  createBannerTC,
  editBannerTC,
  fetchProfileBannerTC,
} from '../../../../store/meddleware/bannerMiddleware';

export const BannerForm: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const isLoggedIn = useAppSelector(
    (state: { authReducer: { isLoggedIn: any } }) => state.authReducer.isLoggedIn,
  );
  const error = useAppSelector(state => state.appReducer.error);
  const layouts = useAppSelector(state => state.layoutReducer.layout?.results);
  const banners = useAppSelector(state => state.bannerReducer.banners?.results);
  const currBannerFromState = useAppSelector(state => state.bannerReducer.currentBanner);

  const [currImage, setCurrImage] = useState<any>(null);
  const [isChosenImg, setIsChosenImg] = useState<boolean>(false);
  const [isSendData, setIsSendData] = useState<boolean>(false);

  const idUrl = params.id;
  const bannerId = Number(idUrl);
  const bannerFromList = banners && banners?.find(res => res.id === bannerId);

  const currentBanner = bannerFromList || currBannerFromState;
  const defaultLayoutValue = currentBanner && currentBanner.layout_info.id;

  BANNER_FORM_DATA.layout.options = layouts?.map(layout => ({
    value: layout.id,
    label: `${layout.slug} [${layout.width}x${layout.height}]`,
  }));

  const defaultBannerData =
    currentBanner &&
    ({
      title: currentBanner.title,
      layout: currentBanner.layout,
      url: currentBanner.url,
      description: currentBanner.description,
      public: currentBanner.public,
    } as IBannerFormData);

  const { handleSubmit, control, reset } = useForm<IBannerFormData>({
    // eslint-disable-next-line consistent-return
    defaultValues: useMemo(() => {
      if (defaultBannerData) {
        return defaultBannerData;
      }
    }, [defaultBannerData]),
  });

  useEffect(() => {
    if (defaultBannerData) {
      reset(defaultBannerData);
    }
  }, [currentBanner]);

  useEffect(() => {
    if (!bannerFromList && bannerId) {
      dispatch(fetchProfileBannerTC(bannerId));
    }
  }, []);

  useEffect(() => {
    setCurrImage(currImage);
    setIsChosenImg(false);
  }, [isChosenImg]);

  useEffect(
    () => () => {
      dispatch(setAppErrorAC({ error: null }));
      setIsSendData(false);
    },
    [],
  );

  const onRemoveImage = (): void => {
    setCurrImage(null);
  };

  const onChangeUpload = (info: UploadChangeParam<UploadFile<any>>): void => {
    setIsChosenImg(true);
    setCurrImage(info.fileList[0].originFileObj);
  };

  const beforeUpload = (file: RcFile): Promise<void> =>
    new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = document.createElement('img');
        img.src = reader.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.naturalWidth;
          canvas.height = img.naturalHeight;
          const ctx = canvas.getContext('2d')!;
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(result => resolve(result as any));
        };
      };
    });

  const onSubmit: SubmitHandler<IBannerFormData> = async data => {
    const newBanner = {
      title: data.title,
      image: currImage && currImage,
      layout: data.layout,
      url: data.url,
      description: data.description,
      public: data.public === undefined ? false : data.public,
    };

    if (!currentBanner) {
      await dispatch(createBannerTC(newBanner));
    }
    if (currentBanner) {
      await dispatch(editBannerTC({ bannerId: currentBanner.id, bannerData: newBanner }));
    }
    setIsSendData(true);
  };

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }
  if (!error && isSendData) {
    return <Navigate to={PATH.PROFILE_BANNERS} />;
  }

  return (
    <div className="banner-form-wrap">
      <form className="banner-form-wrap__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="banner-form-wrap__form__fields-block">
          <div className="banner-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="title"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="title" className="label">
                  Title
                  <Input
                    id="title"
                    className="field"
                    defaultValue={currentBanner ? currentBanner.title : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="banner-form-wrap__form__fields-block__field-wrap upload-wrap">
            <Controller
              control={control}
              name="image"
              render={() => (
                <label htmlFor="image" className="label">
                  Image
                  <Upload
                    id="image"
                    className="upload"
                    listType="picture"
                    onChange={onChangeUpload}
                    beforeUpload={beforeUpload}
                    onRemove={onRemoveImage}
                  >
                    {!currImage && (
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    )}
                  </Upload>
                </label>
              )}
            />
          </div>

          <div className="banner-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="layout"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="layout" className="label">
                  Layout
                  <Select
                    id="layout"
                    className="select"
                    defaultValue={currentBanner ? defaultLayoutValue : null}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    options={BANNER_FORM_DATA.layout.options}
                  />
                </label>
              )}
            />
          </div>

          <div className="banner-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="url"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="url" className="label">
                  Url
                  <Input
                    id="url"
                    className="field"
                    defaultValue={currentBanner ? currentBanner.url : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="banner-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="description" className="label">
                  Description
                  <Input
                    id="description"
                    className="field"
                    defaultValue={currentBanner ? currentBanner.description : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="banner-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="public"
              render={({ field: { onChange, value, ref } }) => (
                <label htmlFor="public" className="label">
                  Public
                  <Checkbox
                    checked={value}
                    id="public"
                    className="radio"
                    defaultChecked={currentBanner ? currentBanner.public : false}
                    onChange={onChange}
                    ref={ref}
                  >
                    Public
                  </Checkbox>
                </label>
              )}
            />
          </div>
        </div>

        <div className="banner-form-wrap__form__btn-wrap">
          <input className="btn-submit" type="submit" value="Sichern" />
        </div>
      </form>
    </div>
  );
};
