import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { bannerApi, IBannerFormData, IPayloadEditBannerData } from '../../api/banner-api';
import { removeToken } from '../../utils/localStorage';
import { setAppErrorAC } from '../AppSlice';
import { setIsLoggedInAC } from '../AuthSlice';

export const fetchProfileBannersTC = createAsyncThunk(
  'banner/fetchProfileBanners',
  async (_, thunkAPI) => {
    try {
      const res = await bannerApi.getBannersList();
      return res.data;
    } catch (e) {
      const error = e as AxiosError;
      const NotAuthStatus = 401;
      if (error.response?.status === NotAuthStatus) {
        thunkAPI.dispatch(setIsLoggedInAC({ isLoggedIn: false }));
        removeToken();
      }
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchProfileBannerTC = createAsyncThunk(
  'banner/fetchProfileBanner',
  async (bannerId: number, thunkAPI) => {
    try {
      const res = await bannerApi.getBanner(bannerId);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const createBannerTC = createAsyncThunk(
  'banner/createBannerTC',
  async (banner: IBannerFormData, thunkAPI) => {
    try {
      const res = await bannerApi.createBanner(banner);
      thunkAPI.dispatch(setAppErrorAC({ error: null }));
      return res.data;
    } catch (e) {
      const error = e as AxiosError;
      const NotAuthStatus = 401;

      thunkAPI.dispatch(setAppErrorAC({ error: error.message }));
      if (error.response?.status === NotAuthStatus) {
        thunkAPI.dispatch(setIsLoggedInAC({ isLoggedIn: false }));
        removeToken();
      }
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const editBannerTC = createAsyncThunk(
  'banner/editBannerTC',
  async (payload: IPayloadEditBannerData, thunkAPI) => {
    try {
      const res = await bannerApi.editBanner(payload);
      thunkAPI.dispatch(setAppErrorAC({ error: null }));
      return res.data;
    } catch (e) {
      const error = e as AxiosError;
      const NotAuthStatus = 401;

      thunkAPI.dispatch(setAppErrorAC({ error: error.message }));
      if (error.response?.status === NotAuthStatus) {
        thunkAPI.dispatch(setIsLoggedInAC({ isLoggedIn: false }));
        removeToken();
      }
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const removeBannerTC = createAsyncThunk(
  'banner/removeBannerTC',
  async (bannerId: number, thunkAPI) => {
    try {
      await bannerApi.removeBanner(bannerId);
      return bannerId;
    } catch (e) {
      const error = e as AxiosError;
      const NotAuthStatus = 401;
      if (error.response?.status === NotAuthStatus) {
        thunkAPI.dispatch(setIsLoggedInAC({ isLoggedIn: false }));
        removeToken();
      }
      return thunkAPI.rejectWithValue(e);
    }
  },
);
