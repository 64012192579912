import React, { FC, memo } from 'react';
import './ControlContainer.scss';

import { Select } from 'antd';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';

import { ICategory } from '../../../../api/categories-api';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { PATH } from '../../../../routes/Routes';
import { setSearchQueryParams } from '../../../../store/CompaniesSlice';
import { fetchRegions } from '../../../../store/meddleware/regionsMiddleware';

interface IControlContainerData {
  category: string;
  region: string;
}

interface IControlContainer {
  categories: ICategory[];
  categoriesID: {
    healthCareCategoryId: number;
    nursingHomesCategoryId: number;
    pharmaciesCategoryId: number;
    suppliesCategoryId: number;
  };
}

export const ControlContainer: FC<IControlContainer> = memo(
  ({ categories, categoriesID }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const regions = useAppSelector(state => state.regionsReducer.regions?.results);

    const REGION_DATA = regions?.map(reg => ({ value: reg.region, label: reg.region }));

    const categoryOption = categories?.map(cat => ({
      value: cat.id.toString(),
      label: cat.name,
    }));
    const CONTROL_CONTAINER_FORM_DATA = {
      category: {
        title: 'Suche nach Kategorie',
        options: categoryOption,
      },
      region: {
        title: 'Stadt wählen',
      },
    };

    const onChangeHandler = (event: string): void => {
      const valueLength = 2;
      if (event.length >= valueLength) {
        dispatch(fetchRegions(event));
      }
    };

    const { handleSubmit, control } = useForm<IControlContainerData>();
    const onSubmit: SubmitHandler<IControlContainerData> = data => {
      dispatch(setSearchQueryParams({ categoryId: data.category, region: data.region }));
      if (+data.category === categoriesID.healthCareCategoryId) {
        navigate(PATH.CARE_SERVICES);
      }
      if (+data.category === categoriesID.nursingHomesCategoryId) {
        navigate(PATH.NURSING_HOMES);
      }
      if (+data.category === categoriesID.pharmaciesCategoryId) {
        navigate(PATH.PHARMACIES);
      }
      if (+data.category === categoriesID.suppliesCategoryId) {
        navigate(PATH.MEDICAL_SUPPLY_STORES);
      }
    };

    return (
      <section className="control-container">
        <div className="control-container__wrap">
          {/* <h1 className="control-container__wrap__title">Pflegeraiting </h1> */}
          <h1 className="control-container__wrap__title">Portal für Gesundheitswesen </h1>
          <div className="control-container__wrap__text">
            Das Portal Pflegeraiting ist ein unabhängiges und kostenloses
            Informationsportal, das Ihnen und Ihren Angehörigen bei der Suche nach einer
            geeigneten Pflegeeinrichtung im gesamten Bundesgebiet hilft. Sie finden hier
            zum Beispiel Informationen über Größe, Kosten, besondere Versorgungsformen
            sowie Lage und Anschriften der Einrichtungen.
          </div>

          <div className="control-container__wrap__category-block">
            {categories &&
              categories.map(category => (
                <NavLink
                  to={`${PATH.HOME}${category.slug}`}
                  key={category.id}
                  className="control-container__wrap__category-block__item"
                >
                  <img src={category.icon} alt={category.name} />
                </NavLink>
              ))}
          </div>
        </div>

        <div className="control-container__search-block">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="control-container__search-block__wrap"
          >
            <Controller
              control={control}
              name="category"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  className="field-select"
                  placeholder={CONTROL_CONTAINER_FORM_DATA.category.title}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  options={CONTROL_CONTAINER_FORM_DATA.category.options}
                  placement="topRight"
                />
              )}
            />

            <Controller
              control={control}
              name="region"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  className="field-select"
                  placeholder={CONTROL_CONTAINER_FORM_DATA.region.title}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  showSearch
                  onSearch={e => {
                    onChangeHandler(e);
                  }}
                  options={REGION_DATA}
                  ref={ref}
                  placement="topRight"
                />
              )}
            />
            <button className="btn-submit" type="submit">
              Jetzt suchen
            </button>
          </form>
        </div>
      </section>
    );
  },
);
