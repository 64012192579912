import React, { FC, memo, useEffect, useState } from 'react';

import './Home.scss';

import { ICompaniesResponse } from '../../../api/companies-api';
import { IBanner } from '../../../api/layout-api';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { fetchCategories } from '../../../store/meddleware/categoriesMiddleware';
import { fetchCompaniesByCategory } from '../../../store/meddleware/companiesMiddleware';
import { fetchLayout } from '../../../store/meddleware/layoutMiddleware';
import { getRandomItem } from '../../../utils/getRandomItem';

import { AdvertisingListContainer } from './AdvertisingListContainer/AdvertisingListContainer';
import { CategoryContainer } from './CategoryContainer/CategoryContainer';
import { ControlContainer } from './ControlContainer/ControlContainer';
import { NewsContainer } from './NewsContainer/NewsContainer';
import { TextContainer } from './TextContainer/TextContainer';

export const Home: FC = memo(() => {
  const dispatch = useAppDispatch();

  const categories = useAppSelector(state => state.categoriesReducer.categories?.results);
  const layoutsList = useAppSelector(state => state.layoutReducer.layout?.results);
  const companies = useAppSelector(state => state.companiesReducer.companiesByCategory);
  const healthCareCompanies = useAppSelector(
    state => state.companiesReducer.companiesByCategory.health_care?.results,
  );
  const nursingHomesCompanies = useAppSelector(
    state => state.companiesReducer.companiesByCategory.nursing_homes?.results,
  );
  const pharmaciesCompanies = useAppSelector(
    state => state.companiesReducer.companiesByCategory.pharmacies?.results,
  );
  const suppliesCompanies = useAppSelector(
    state => state.companiesReducer.companiesByCategory.supplies?.results,
  );

  const healthCareCategoryId = 5;
  const nursingHomesCategoryId = 6;
  const pharmaciesCategoryId = 7;
  const suppliesCategoryId = 8;
  const categoriesID = {
    healthCareCategoryId,
    nursingHomesCategoryId,
    pharmaciesCategoryId,
    suppliesCategoryId,
  };

  const [currentBanner, setCurrentBanner] = useState<IBanner>();
  const [currentUnternehmenBanner1, setCurrentUnternehmenBanner1] = useState<IBanner>();
  const [currentUnternehmenBanner2, setCurrentUnternehmenBanner2] = useState<IBanner>();

  const INDEX_AFTER_FIRST_SECTION = layoutsList?.find(
    layout => layout.slug === 'index-after-first-section',
  );

  const INDEX_UNTERNEHMEN_BIG = layoutsList?.find(
    layout => layout.slug === 'index-unterhenmen-big',
  );

  const INDEX_CAROUSEL = layoutsList?.find(layout => layout.slug === 'index-carousel');

  useEffect(() => {
    if (!categories) {
      dispatch(fetchCategories());
    }
  }, []);

  useEffect(() => {
    if (!layoutsList) {
      dispatch(fetchLayout());
    }
  }, []);

  useEffect(() => {
    if (INDEX_AFTER_FIRST_SECTION) {
      setCurrentBanner(getRandomItem(INDEX_AFTER_FIRST_SECTION?.banners) as IBanner);
    }
    if (INDEX_UNTERNEHMEN_BIG) {
      setCurrentUnternehmenBanner1(
        getRandomItem(INDEX_UNTERNEHMEN_BIG?.banners) as IBanner,
      );
      setCurrentUnternehmenBanner2(
        getRandomItem(INDEX_UNTERNEHMEN_BIG?.banners) as IBanner,
      );
    }
  }, [layoutsList]);

  useEffect(() => {
    if (!healthCareCompanies) {
      dispatch(fetchCompaniesByCategory(healthCareCategoryId.toString()));
    }
    if (!nursingHomesCompanies) {
      dispatch(fetchCompaniesByCategory(nursingHomesCategoryId.toString()));
    }
    if (!pharmaciesCompanies) {
      dispatch(fetchCompaniesByCategory(pharmaciesCategoryId.toString()));
    }
    if (!suppliesCompanies) {
      dispatch(fetchCompaniesByCategory(suppliesCategoryId.toString()));
    }
  }, []);

  const getCurrentCategoryCompanies = (
    categoryId: number,
  ): ICompaniesResponse | undefined => {
    if (categoryId === healthCareCategoryId) {
      return companies.health_care;
    }
    if (categoryId === nursingHomesCategoryId) {
      return companies.nursing_homes;
    }
    if (categoryId === pharmaciesCategoryId) {
      return companies.pharmacies;
    }
    if (categoryId === suppliesCategoryId) {
      return companies.supplies;
    }
    return undefined;
  };

  return (
    <div className="root_home">
      <ControlContainer categories={categories} categoriesID={categoriesID} />

      <div className="root_home__title-text-mobile">
        <h1 className="root_home__title-text-mobile__title">
          Portal für Gesundheitswesen{' '}
        </h1>
        <div className="root_home__title-text-mobile__text">
          Das Portal Pflegeraiting ist ein unabhängiges und kostenloses
          Informationsportal, das Ihnen und Ihren Angehörigen bei der Suche nach einer
          geeigneten Pflegeeinrichtung im gesamten Bundesgebiet hilft. Sie finden hier zum
          Beispiel Informationen über Größe, Kosten, besondere Versorgungsformen sowie
          Lage und Anschriften der Einrichtungen.
        </div>
      </div>

      <div className="root_home__advertising_banner">
        <a className="root_home__advertising_banner__link" href={currentBanner?.url}>
          <img
            src={currentBanner?.image}
            alt={currentBanner?.title}
            className="root_home__advertising_banner__link__item"
          />
        </a>
      </div>

      <CategoryContainer
        categories={categories}
        getCurrentCategoryCompanies={getCurrentCategoryCompanies}
      />

      <TextContainer banners={{ currentUnternehmenBanner1, currentUnternehmenBanner2 }} />

      <NewsContainer />

      <AdvertisingListContainer banners={INDEX_CAROUSEL} />
    </div>
  );
});
