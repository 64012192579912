import React, { FC } from 'react';
import './RegistrationForm.scss';

import { Input, Select } from 'antd';
import Radio from 'antd/lib/radio/radio';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';

import { REGISTRATION_FORM_DATA } from '../../../api/auth-api';
import { IRegistrationData } from '../../../api/authService';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { PATH } from '../../../routes/Routes';
import { registrationTC } from '../../../store/meddleware/authMiddleware';

export const RegistrationForm: FC = () => {
  const dispatch = useAppDispatch();

  const isLoggedIn = useAppSelector(state => state.authReducer.isLoggedIn);

  const { handleSubmit, control } = useForm<IRegistrationData>();
  const onSubmit: SubmitHandler<IRegistrationData> = data => {
    dispatch(registrationTC(data));
  };

  if (isLoggedIn) {
    return <Navigate to={PATH.HOME} />;
  }

  return (
    <section className="root__registration">
      <form
        className="root__registration__registration-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="root__registration__registration-form__title">Registrierung</h1>
        <div className="root__registration__registration-form__fields-block">
          <h3 className="category-title">Persönliche Informationen</h3>
          <div className="category">
            <div className="root__registration__registration-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="gender"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="gender" className="label">
                    {REGISTRATION_FORM_DATA.gender.title}
                    <Select
                      id="gender"
                      className="select"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                      options={REGISTRATION_FORM_DATA.gender.options}
                    />
                  </label>
                )}
              />
            </div>

            <div className="root__registration__registration-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="first_name"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="first_name" className="label">
                    {REGISTRATION_FORM_DATA.first_name.title}
                    <Input
                      id="first_name"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>

            <div className="root__registration__registration-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="last_name"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="last_name" className="label">
                    {REGISTRATION_FORM_DATA.last_name.title}
                    <Input
                      id="last_name"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>
          </div>

          <h3 className="category-title">Kontakt</h3>
          <div className="category">
            <div className="root__registration__registration-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="email"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="email" className="label">
                    {REGISTRATION_FORM_DATA.email.title}
                    <Input
                      id="email"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>

            <div className="root__registration__registration-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="phone" className="label">
                    {REGISTRATION_FORM_DATA.phone.title}
                    <Input
                      id="phone"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>
          </div>

          <h3 className="category-title">Adresse</h3>
          <div className="category">
            <div className="root__registration__registration-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="sity"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="city" className="label">
                    {REGISTRATION_FORM_DATA.city.title}
                    <Input
                      id="city"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>

            <div className="root__registration__registration-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="street"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="street" className="label">
                    {REGISTRATION_FORM_DATA.street.title}
                    <Input
                      id="street"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>

            <div className="root__registration__registration-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="street_number"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="street_number" className="label">
                    {REGISTRATION_FORM_DATA.street_number.title}
                    <Input
                      id="street_number"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>

            <div className="root__registration__registration-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="zip_code"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="zip_code" className="label">
                    {REGISTRATION_FORM_DATA.zip_code.title}
                    <Input
                      id="zip_code"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>
          </div>

          <h3 className="category-title">Benutzerinformation</h3>
          <div className="category">
            <div className="root__registration__registration-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="username"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="username" className="label">
                    {REGISTRATION_FORM_DATA.username.title}
                    <Input
                      id="username"
                      className="field"
                      type="text"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>

            <div className="root__registration__registration-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="password" className="label">
                    {REGISTRATION_FORM_DATA.password.title}
                    <Input.Password
                      id="password"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>

            <div className="root__registration__registration-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="password_repeat"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="password_repeat" className="label">
                    {REGISTRATION_FORM_DATA.password_repeat.title}
                    <Input.Password
                      id="password_repeat"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>
          </div>

          <h3 className="category-title">Firmeninfo</h3>
          <div className="category">
            <div className="root__registration__registration-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="company_name"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="company_name" className="label">
                    {REGISTRATION_FORM_DATA.company_name.title}
                    <Input
                      id="company_name"
                      className="field"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  </label>
                )}
              />
            </div>

            <div className="root__registration__registration-form__fields-block__field-wrap">
              <Controller
                control={control}
                name="tariff"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <label htmlFor="tariff" className="label">
                    {REGISTRATION_FORM_DATA.tariff.title}
                    <Select
                      // defaultValue=""
                      id="tariff"
                      className="select"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                      options={REGISTRATION_FORM_DATA.tariff.options}
                    />
                  </label>
                )}
              />
            </div>
          </div>
        </div>

        <div className="root__registration__registration-form__fields-block__field-wrap">
          <Controller
            control={control}
            name="send_email"
            render={({ field: { onChange, value, ref } }) => (
              <label htmlFor="send_email" className="label">
                {REGISTRATION_FORM_DATA.send_email.title}
                <Radio
                  value={value}
                  id="send_email"
                  className="radio"
                  onChange={onChange}
                  ref={ref}
                >
                  Empfang von Newsletter und Rechnungen
                </Radio>
              </label>
            )}
          />
        </div>

        <div className="root__registration__registration-form__btn-wrap">
          <input className="btn-submit" type="submit" value="Abschicken" />
        </div>
      </form>
    </section>
  );
};
