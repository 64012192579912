import { createSlice } from '@reduxjs/toolkit';

import { IRegionsResponse } from '../api/regions-api';

import { fetchRegions } from './meddleware/regionsMiddleware';

interface ICategoriesState {
  regions: IRegionsResponse;
}

const initialState: ICategoriesState = {
  regions: {} as IRegionsResponse,
};

export const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchRegions.fulfilled, (state, action) => {
      state.regions = action.payload;
    });
  },
});

export const regionsReducer = regionsSlice.reducer;
