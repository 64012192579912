import { createAsyncThunk } from '@reduxjs/toolkit';

import { pageApi } from '../../api/page-api';
import { setLoadingStatusAC } from '../AppSlice';

export const fetchPage = createAsyncThunk(
  'page/fetchPage',
  async (url: string, thunkAPI) => {
    thunkAPI.dispatch(setLoadingStatusAC({ status: true }));
    try {
      const res = await pageApi.getPage(url);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    } finally {
      thunkAPI.dispatch(setLoadingStatusAC({ status: false }));
    }
  },
);
