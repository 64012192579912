import React, { FC } from 'react';

import './Footer.scss';
import { NavLink } from 'react-router-dom';

import { logo } from '../../../assets/img';
import loginImg from '../../../assets/img/svg/login-solid.svg';
import logoutImg from '../../../assets/img/svg/logout-solid.svg';
import signupImg from '../../../assets/img/svg/signup-solid.svg';
import userImg from '../../../assets/img/svg/user-solid.svg';
import { useAppSelector } from '../../../hooks/redux';
import { useLogout } from '../../../hooks/useLogout';
import { PATH } from '../../../routes/Routes';
import { executeScroll } from '../../../utils/executeScroll';

export const Footer: FC = () => {
  const isLoggedIn = useAppSelector(state => state.authReducer.isLoggedIn);
  const logoutHandler = useLogout();

  return (
    <footer className="footer">
      <div className="footer__middle-block">
        <div className="footer__middle-block__logo-wrap">
          <NavLink to={PATH.HOME} onClick={executeScroll}>
            <img
              className="footer__middle-block__logo-wrap__logo"
              src={logo.pflegerating_logo}
              alt="pflegerating-logo"
            />
          </NavLink>
        </div>

        <nav className="footer__middle-block__nav">
          <ul className="footer__middle-block__nav__menu">
            <li className="footer__middle-block__nav__menu__list-item">
              {!isLoggedIn && (
                <NavLink
                  to={PATH.LOGIN}
                  onClick={executeScroll}
                  className="footer__middle-block__nav__menu__list-item__link"
                >
                  <img src={loginImg} alt="login" className="img" /> Login
                </NavLink>
              )}
              {isLoggedIn && (
                <NavLink
                  to={PATH.PROFILE}
                  onClick={executeScroll}
                  className="footer__middle-block__nav__menu__list-item__link"
                >
                  <img src={userImg} alt="Admin" className="img" /> Admin
                </NavLink>
              )}
            </li>
            <li className="footer__middle-block__nav__menu__list-item">
              {!isLoggedIn && (
                <NavLink
                  to={PATH.SIGN_UP}
                  onClick={executeScroll}
                  className="footer__middle-block__nav__menu__list-item__link"
                >
                  <img src={signupImg} alt="registration" className="img registr" />
                  Registration
                </NavLink>
              )}
              {isLoggedIn && (
                <NavLink
                  to={PATH.HOME}
                  onClick={() => {
                    logoutHandler();
                    executeScroll();
                  }}
                  className="footer__middle-block__nav__menu__list-item__link"
                >
                  <img src={logoutImg} alt="logout" className="img registr" />
                  Exit
                </NavLink>
              )}
            </li>
          </ul>

          <ul className="footer__middle-block__nav__category">
            <li className="footer__middle-block__nav__category__list-item">
              <NavLink
                to={PATH.CARE_SERVICES}
                className="footer__middle-block__nav__category__list-item__link"
              >
                Pflegedienste
              </NavLink>
            </li>
            <li className="footer__middle-block__nav__category__list-item">
              <NavLink
                to={PATH.NURSING_HOMES}
                className="footer__middle-block__nav__category__list-item__link"
              >
                Pflegeheime
              </NavLink>
            </li>
            <li className="footer__middle-block__nav__category__list-item">
              <NavLink
                to={PATH.PHARMACIES}
                className="footer__middle-block__nav__category__list-item__link"
              >
                Apotheken
              </NavLink>
            </li>
            <li className="footer__middle-block__nav__category__list-item">
              <NavLink
                to={PATH.MEDICAL_SUPPLY_STORES}
                className="footer__middle-block__nav__category__list-item__link"
              >
                Sanitätshäuser
              </NavLink>
            </li>
          </ul>

          <ul className="footer__middle-block__nav__about-us">
            <li className="footer__middle-block__nav__category__list-item">
              <NavLink
                to={PATH.IMPRESSUM}
                onClick={executeScroll}
                className="footer__middle-block__nav__category__list-item__link"
              >
                Impressum
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>

      <div className="footer__bottom-block">
        <div className="footer__bottom-block__copyright">
          Copyright © 2015—2022 «PFLEGERATING»
        </div>
      </div>
    </footer>
  );
};
