import { createAsyncThunk } from '@reduxjs/toolkit';

import { categoriesApi } from '../../api/categories-api';

export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async (_, thunkAPI) => {
    try {
      const res = await categoriesApi.getCategoriesList();
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);
