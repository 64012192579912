import { AxiosResponse } from 'axios';

import { instance } from './http';

export const messageApi = {
  sendMessage(data: IMessage) {
    return instance.post<IMessage, AxiosResponse<IMessage>>('api/message/', data);
  },
};

export interface IMessage {
  company: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}
