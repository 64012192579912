import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { authAPI } from '../api/authService';

import { appReducer } from './AppSlice';
import { authReducer } from './AuthSlice';
import { bannerReducer } from './BannerSlice';
import { categoriesReducer } from './CategoriesSlice';
import { companiesReducer } from './CompaniesSlice';
import { institutionReducer } from './InstitutionSlice';
import { layoutReducer } from './LayoutSlice';
import { pageReducer } from './PageSlice';
import { profileReducer } from './ProfileSlice';
import { regionsReducer } from './RegionsSlice';

const rootReducer = combineReducers({
  authReducer,
  categoriesReducer,
  companiesReducer,
  layoutReducer,
  profileReducer,
  bannerReducer,
  institutionReducer,
  regionsReducer,
  appReducer,
  pageReducer,
  [authAPI.reducerPath]: authAPI.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(authAPI.middleware),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
