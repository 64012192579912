import { createAsyncThunk } from '@reduxjs/toolkit';

import { layoutApi } from '../../api/layout-api';

export const fetchLayout = createAsyncThunk('layout/fetchLayout', async (_, thunkAPI) => {
  try {
    const res = await layoutApi.getLayout();
    return res.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
