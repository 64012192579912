import { AxiosResponse } from 'axios';

import { instance } from './http';

export const pageApi = {
  getPage(url: string) {
    return instance.get<PageResponse, AxiosResponse<PageResponse>>(`api/page${url}`);
  },
};

export interface PageResponse {
  id: number;
  name: string;
  title: string;
  header: string;
  keywords: any;
  description: any;
  head_code: string;
  footer_code: string;
  image: any;
  intro: string;
  text: string;
  sites: number[];
  slug: string;
  url: string;
  template: any;
  per_page: number;
  position: number;
  public: boolean;
  created_at: string;
  updated_at: string;
}
