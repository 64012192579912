import React, { FC } from 'react';

import './Admin.scss';
import { Navigate, NavLink, useLocation, useParams } from 'react-router-dom';

import addImg from '../../assets/img/svg/plus-solid.svg';
import { useAppSelector } from '../../hooks/redux';
import { PATH } from '../../routes/Routes';
import { BannerForm } from '../Forms/AdminForms/BannerForm/BannerForm';
import { InstitutionForm } from '../Forms/AdminForms/InstitutionForm/InstitutionForm';

import { ProfileBanners } from './Banners/ProfileBanners';
import { ProfileCompanies } from './Companies/ProfileCompanies';
import { ViewProfile } from './ViewProfile/ViewProvile';

export const Admin: FC = () => {
  const location = useLocation();
  const params = useParams();

  const isLoggedIn = useAppSelector(state => state.authReducer.isLoggedIn);

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }

  return (
    <div className="admin-page">
      <div className="admin-page__wrap">
        <h3 className="admin-page__wrap__title">
          {location.pathname === PATH.PROFILE && <>Private office</>}
          {location.pathname === PATH.PROFILE_BANNERS && (
            <>
              Banners{' '}
              <NavLink to={PATH.BANNER_FORM} className="admin-page__wrap__title__link">
                <img src={addImg} alt="add" /> banner hinzufügen
              </NavLink>
            </>
          )}
          {location.pathname === PATH.PROFILE_COMPANIES && (
            <>
              Unternehmen{' '}
              <NavLink
                to={PATH.INSTITUTION_FORM}
                className="admin-page__wrap__title__link"
              >
                <img src={addImg} alt="add" /> unternehmen hinzufügen
              </NavLink>
            </>
          )}
        </h3>
        <div className="admin-page__wrap__menu">
          <aside className="admin-page__wrap__menu__sidebar">
            <nav className="admin-page__wrap__menu__sidebar__nav">
              <NavLink
                to={PATH.PROFILE}
                className="admin-page__wrap__menu__sidebar__nav__link"
              >
                View profile
              </NavLink>
              <NavLink
                to={PATH.PROFILE_COMPANIES}
                className="admin-page__wrap__menu__sidebar__nav__link"
              >
                Unternehmen
              </NavLink>
              <NavLink
                to={PATH.PROFILE_BANNERS}
                className="admin-page__wrap__menu__sidebar__nav__link"
              >
                Banner
              </NavLink>
            </nav>
          </aside>
          <div className="admin-page__wrap__menu__content">
            {location.pathname === PATH.PROFILE && <ViewProfile />}
            {location.pathname === PATH.PROFILE_COMPANIES && <ProfileCompanies />}
            {location.pathname === PATH.PROFILE_BANNERS && <ProfileBanners />}
            {location.pathname === PATH.BANNER_FORM && <BannerForm />}
            {location.pathname === `${PATH.BANNER_FORM}${params.id}/` && <BannerForm />}
            {location.pathname === PATH.INSTITUTION_FORM && <InstitutionForm />}
            {location.pathname === `${PATH.INSTITUTION_FORM}${params.id}/` && (
              <InstitutionForm />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
