import { AxiosResponse } from 'axios';

import { instance } from './http';

export const layoutApi = {
  getLayout() {
    return instance.get<ILayoutListResponse, AxiosResponse<ILayoutListResponse>>(
      'api/layout/',
    );
  },
};

export interface IBanner {
  id: number;
  title: string;
  image: string;
  url: string;
  description: string;
  public: boolean;
}

export interface LayoutItem {
  id: number;
  slug: string;
  title: string;
  width: string;
  height: string;
  banners: IBanner[];
}

export interface ILayoutListResponse {
  count: number;
  links: {
    next: string | null;
    prev: string | null;
  };
  num_pages: number;
  current_page: number;
  results: LayoutItem[];
}
