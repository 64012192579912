import { AxiosResponse } from 'axios';

import { instance } from './http';

export const categoriesApi = {
  getCategoriesList() {
    return instance.get<ICategoriesResponse, AxiosResponse<ICategoriesResponse>>(
      'api/categories/',
    );
  },
};

export interface ICategory {
  id: number;
  name: string;
  slug: string;
  marker: string;
  icon: string;
  public: boolean;
  created_at: string;
  updated_at: string;
}

export interface ICategoriesResponse {
  count: number;
  links: {
    next: string | null;
    prev: string | null;
  };
  num_pages: number;
  current_page: number;
  results: ICategory[];
}
