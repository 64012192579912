import React, { FC } from 'react';

import { useAppSelector } from '../../../hooks/redux';
import { CompanyListPage } from '../CompanyListPage';

export const Pharmacies: FC = () => {
  const CURR_CATEGORY_ID = 7;

  const companies = useAppSelector(
    state => state.companiesReducer.searchCompaniesList.pharmacies?.results,
  );
  const companiesNumPages = useAppSelector(
    state => state.companiesReducer.searchCompaniesList.pharmacies?.num_pages,
  );
  const companiesCurrentPage = useAppSelector(
    state => state.companiesReducer.searchCompaniesList.pharmacies?.current_page,
  );
  const nextLink = useAppSelector(
    state => state.companiesReducer.searchCompaniesList.pharmacies?.links?.next,
  );
  const prevLink = useAppSelector(
    state => state.companiesReducer.searchCompaniesList.pharmacies?.links?.prev,
  );
  const currentRegion = useAppSelector(
    state => state.companiesReducer.currentRegion.pharmacies,
  );

  return (
    <CompanyListPage
      categoryId={CURR_CATEGORY_ID}
      companies={companies}
      companiesNumPages={companiesNumPages}
      companiesCurrentPage={companiesCurrentPage}
      nextLink={nextLink}
      prevLink={prevLink}
      currentRegion={currentRegion}
    />
  );
};
