/* eslint-disable */
import axios from 'axios';
import { loadToken } from '../../utils/localStorage';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use((config: any) => {
  return config;
});

// export default instance;

export const instanceAdmin = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instanceAdmin.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${loadToken()}`;
  return config;
});

instanceAdmin.interceptors.response.use((config: any) => {
  return config;
}, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && error.config && !error.config._isRetry) {
    originalRequest._isRetry = true;
    try {
      const token = loadToken();
      axios.post(`${process.env.REACT_APP_BASE_URL}/token/verify/`, {token: token})

      return instance.request(originalRequest);
    } catch (e) {
      // useLogout();
    }
  }
  throw error;
});
