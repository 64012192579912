import { createSlice } from '@reduxjs/toolkit';

import { PageResponse } from '../api/page-api';

import { fetchPage } from './meddleware/pageMiddleware';

interface PageState {
  page: PageResponse;
}

const initialState: PageState = {
  page: {
    text: '',
  } as PageResponse,
};

export const pageSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPage.fulfilled, (state, action) => {
      state.page = action.payload;
    });
  },
});

export const pageReducer = pageSlice.reducer;
