import React, { FC } from 'react';

import { Route, Routes } from 'react-router-dom';

import { Admin } from '../pages/Admin/Admin';
import { CompanyDescription } from '../pages/CompanyDescription/CompanyDescription';
import { CareServices } from '../pages/CompanyListPages/CareServices/CareServices';
import { MedicalSupplyStores } from '../pages/CompanyListPages/MedicalSupplyStores/MedicalSupplyStores';
import { NursingHomes } from '../pages/CompanyListPages/NursingHomes/NursingHomes';
import { Pharmacies } from '../pages/CompanyListPages/Pharmacies/Pharmacies';
import { LoginForm } from '../pages/Forms/LoginForm/LoginForm';
import { RegistrationForm } from '../pages/Forms/RegistrationForm/RegistrationForm';
import { Impressum } from '../pages/Impressum/Impressum';
import { Home } from '../pages/Main/Home/Home';
import { NotFound } from '../pages/NotFound/NotFound';
import { Order } from '../pages/Order/Order';
import { Tariffs } from '../pages/Tariffs/Tariffs';

export const PATH = {
  HOME: '/',
  LOGIN: '/login/',
  SIGN_UP: '/singup/',
  PROFILE: '/profile/',
  PROFILE_COMPANIES: '/profile/companies/',
  PROFILE_BANNERS: '/profile/banners/',
  BANNER_FORM: '/profile/banner/',
  EDIT_BANNER_FORM: '/profile/banner/:id',
  INSTITUTION_FORM: '/profile/institution/',
  EDIT_INSTITUTION_FORM: '/profile/institution/:id',
  CARE_SERVICES: '/health-care/',
  NURSING_HOMES: '/nursing-homes/',
  PHARMACIES: '/pharmacies/',
  MEDICAL_SUPPLY_STORES: '/supplies/',
  TARIFS: '/tariffs/',
  ORDER: '/order/',
  IMPRESSUM: '/impressum/',
  ORDER_ID: '/order/:id/',
  COMPANY_ITEM: '/company_item/',
  COMPANY_ITEM_ID: '/company_item/:id/',
  ERROR_404: '/error404/',
};

export const RoutesComponent: FC = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path={PATH.HOME} element={<Home />} />
    <Route path={PATH.LOGIN} element={<LoginForm />} />
    <Route path={PATH.SIGN_UP} element={<RegistrationForm />} />
    <Route path={PATH.PROFILE} element={<Admin />} />
    <Route path={PATH.PROFILE_COMPANIES} element={<Admin />} />
    <Route path={PATH.PROFILE_BANNERS} element={<Admin />} />
    <Route path={PATH.BANNER_FORM} element={<Admin />} />
    <Route path={PATH.EDIT_BANNER_FORM} element={<Admin />} />
    <Route path={PATH.INSTITUTION_FORM} element={<Admin />} />
    <Route path={PATH.EDIT_INSTITUTION_FORM} element={<Admin />} />
    <Route path={PATH.ORDER_ID} element={<Order />} />
    <Route path={PATH.CARE_SERVICES} element={<CareServices />} />
    <Route path={PATH.NURSING_HOMES} element={<NursingHomes />} />
    <Route path={PATH.PHARMACIES} element={<Pharmacies />} />
    <Route path={PATH.MEDICAL_SUPPLY_STORES} element={<MedicalSupplyStores />} />
    <Route path={PATH.TARIFS} element={<Tariffs />} />
    <Route path={PATH.IMPRESSUM} element={<Impressum />} />
    <Route path={PATH.COMPANY_ITEM_ID} element={<CompanyDescription />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
