import { setIsLoggedInAC } from '../store/AuthSlice';
import { removeToken, saveToken } from '../utils/localStorage';

import { useAppDispatch } from './redux';

/**
 * "useLogout" is a custom logout hook. Returns a function that clears local storage,
 * application state, and modifies "isLoggedIn: false, isInitialized: false".
 * */
export const useLogout = (): (() => void) => {
  const dispatch = useAppDispatch();

  return () => {
    saveToken('');
    removeToken();
    dispatch(setIsLoggedInAC({ isLoggedIn: false }));
  };
};
