import React, { FC, useEffect, useState } from 'react';

import './Order.scss';

import { IBanner } from '../../api/layout-api';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { fetchLayout } from '../../store/meddleware/layoutMiddleware';
import { getRandomItem } from '../../utils/getRandomItem';

import { OrderForm } from './OrderForm/OrderForm';

export const Order: FC = () => {
  const dispatch = useAppDispatch();

  const layoutsList = useAppSelector(state => state.layoutReducer.layout?.results);
  const [currentOrderSmallBanner, setCurrentOrderSmallBanner] = useState<IBanner>();
  const [currentOrderBigBanner, setCurrentOrderBigBanner] = useState<IBanner>();

  const ORDER_RIGHTCOL_SMALL = layoutsList?.find(
    layout => layout.slug === 'order-rightcol-small',
  );
  const ORDER_RIGHTCOL_BIG = layoutsList?.find(
    layout => layout.slug === 'order-rightcol-big',
  );

  useEffect(() => {
    if (!layoutsList) {
      dispatch(fetchLayout());
    }
  }, []);

  useEffect(() => {
    if (ORDER_RIGHTCOL_SMALL) {
      setCurrentOrderSmallBanner(getRandomItem(ORDER_RIGHTCOL_SMALL?.banners) as IBanner);
    }
    if (ORDER_RIGHTCOL_BIG) {
      setCurrentOrderBigBanner(getRandomItem(ORDER_RIGHTCOL_BIG?.banners) as IBanner);
    }
  }, [layoutsList]);

  return (
    <section className="root__order">
      <div className="root__order__wrap">
        <div className="root__order__wrap__left">
          <div className="root__order__wrap__left__title-block">
            <h1 className="root__order__wrap__left__title-block__title">
              Wie finde ich den passenden Pflegedienst?
            </h1>
            <div className="root__order__wrap__left__title-block__desc">
              Passt der Pflegedienst zu meinen Wünschen und Bedürfnissen? Kontaktieren Sie
              uns, wir finden es gemeinsam heraus!
            </div>
          </div>

          <OrderForm />
        </div>

        <div className="root__order__wrap__right">
          <div className="root__order__wrap__left__banner-small">
            <a
              href={currentOrderSmallBanner?.url}
              className="root__order__wrap__right__banner-small__link"
            >
              <img
                src={currentOrderSmallBanner?.image}
                alt={currentOrderSmallBanner?.title}
              />
            </a>
          </div>

          <div className="root__order__wrap__left__banner-big">
            <a
              href={currentOrderBigBanner?.url}
              className="root__order__wrap__right__banner-big__link"
            >
              <img
                src={currentOrderBigBanner?.image}
                alt={currentOrderBigBanner?.title}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
