import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
  IInstitutionFormData,
  institutionApi,
  IPayloadEditInstitutionData,
} from '../../api/institution-api';
import { removeToken } from '../../utils/localStorage';
import { setAppErrorAC } from '../AppSlice';
import { setIsLoggedInAC } from '../AuthSlice';

export const fetchProfileInstitutionsTC = createAsyncThunk(
  'institution/fetchProfileInstitutions',
  async (_, thunkAPI) => {
    try {
      const res = await institutionApi.getInstitutionList();
      return res.data;
    } catch (e) {
      const error = e as AxiosError;
      const NotAuthStatus = 401;
      if (error.response?.status === NotAuthStatus) {
        thunkAPI.dispatch(setIsLoggedInAC({ isLoggedIn: false }));
        removeToken();
      }
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchProfileInstitutionTC = createAsyncThunk(
  'institution/fetchProfileInstitution',
  async (institutionId: number, thunkAPI) => {
    try {
      const res = await institutionApi.getInstitution(institutionId);
      return res.data;
    } catch (e) {
      const error = e as AxiosError;
      const NotAuthStatus = 401;
      if (error.response?.status === NotAuthStatus) {
        thunkAPI.dispatch(setIsLoggedInAC({ isLoggedIn: false }));
        removeToken();
      }
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const createInstitutionTC = createAsyncThunk(
  'institution/createInstitution',
  async (institution: IInstitutionFormData, thunkAPI) => {
    try {
      const res = await institutionApi.createInstitution(institution);
      thunkAPI.dispatch(setAppErrorAC({ error: null }));
      return res.data;
    } catch (e) {
      const error = e as AxiosError;
      const NotAuthStatus = 401;

      thunkAPI.dispatch(setAppErrorAC({ error: error.message }));
      if (error.response?.status === NotAuthStatus) {
        thunkAPI.dispatch(setIsLoggedInAC({ isLoggedIn: false }));
        removeToken();
      }
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const editInstitutionTC = createAsyncThunk(
  'institution/editInstitution',
  async (payload: IPayloadEditInstitutionData, thunkAPI) => {
    try {
      const res = await institutionApi.editInstitution(payload);
      thunkAPI.dispatch(setAppErrorAC({ error: null }));
      return res.data;
    } catch (e) {
      const error = e as AxiosError;
      const NotAuthStatus = 401;

      thunkAPI.dispatch(setAppErrorAC({ error: error.message }));
      if (error.response?.status === NotAuthStatus) {
        thunkAPI.dispatch(setIsLoggedInAC({ isLoggedIn: false }));
        removeToken();
      }
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const removeInstitutionTC = createAsyncThunk(
  'institution/removeInstitution',
  async (institutionId: number, thunkAPI) => {
    try {
      await institutionApi.removeInstitution(institutionId);
      return institutionId;
    } catch (e) {
      const error = e as AxiosError;
      const NotAuthStatus = 401;
      if (error.response?.status === NotAuthStatus) {
        thunkAPI.dispatch(setIsLoggedInAC({ isLoggedIn: false }));
        removeToken();
      }
      return thunkAPI.rejectWithValue(e);
    }
  },
);
