import React, { FC } from 'react';

import './Header.scss';
import { NavLink } from 'react-router-dom';

import { logo } from '../../../assets/img';
import { PATH } from '../../../routes/Routes';

export const Header: FC = () => (
  <header className="root__header">
    <div className="wrap">
      <div className="root__header__logo-wrap">
        <NavLink to={PATH.HOME}>
          <img
            className="root__header__logo-wrap__logo"
            src={logo.pflegerating_logo}
            alt="pflegerating-logo"
          />
        </NavLink>
      </div>
      <div className="root__header__wrap">
        <nav className="root__header__wrap__nav">
          <ul className="root__header__wrap__nav__list">
            <li className="root__header__wrap__nav__list__item mobile500">
              <NavLink
                to={PATH.CARE_SERVICES}
                className="root__header__wrap__nav__list__item__link"
              >
                Pflegedienste
              </NavLink>
            </li>
            <li className="root__header__wrap__nav__list__item mobile500">
              <NavLink
                to={PATH.NURSING_HOMES}
                className="root__header__wrap__nav__list__item__link"
              >
                Pflegeheime
              </NavLink>
            </li>
            <li className="root__header__wrap__nav__list__item mobile500">
              <NavLink
                to={PATH.PHARMACIES}
                className="root__header__wrap__nav__list__item__link"
              >
                Apotheken
              </NavLink>
            </li>
            <li className="root__header__wrap__nav__list__item mobile500">
              <NavLink
                to={PATH.MEDICAL_SUPPLY_STORES}
                className="root__header__wrap__nav__list__item__link"
              >
                Sanitätshäuser
              </NavLink>
            </li>
            <li className="root__header__wrap__nav__list__item">
              <NavLink
                to={PATH.TARIFS}
                className="root__header__wrap__nav__list__item__tarif-link"
              >
                Für Pflegeanbieter
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
);
