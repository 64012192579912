import React, { FC, useEffect } from 'react';

import { Select } from 'antd';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import './ListsFilter.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { setCurrentRegion } from '../../../store/CompaniesSlice';
import { fetchRegions } from '../../../store/meddleware/regionsMiddleware';

interface IListsFilterData {
  region: string;
}

interface IListsFilterProps {
  searchCallback: (region: string) => void;
  currentRegion: string | null;
  categoryId: number;
}

export const ListsFilter: FC<IListsFilterProps> = ({
  searchCallback,
  currentRegion,
  categoryId,
}) => {
  const dispatch = useAppDispatch();

  const regions = useAppSelector(state => state.regionsReducer.regions?.results);

  const REGION_DATA = regions?.map(reg => ({ value: reg.region, label: reg.region }));

  // const regionDefaultValue = {
  //   value: currentRegion,
  //   label: currentRegion,
  // };

  useEffect(() => {
    if (!regions) {
      dispatch(fetchRegions(''));
    }
  }, []);

  const { handleSubmit, control } = useForm<IListsFilterData>();
  const onSubmit: SubmitHandler<IListsFilterData> = data => {
    searchCallback(data.region);
  };

  const onChangeHandler = (event: string): void => {
    const valueLength = 2;
    if (event.length >= valueLength) {
      dispatch(fetchRegions(event));
    }
  };

  const clearCurrentRegion = (): void => {
    dispatch(setCurrentRegion({ categoryId: categoryId.toString(), region: null }));
  };

  return (
    <div>
      <h2>Search result</h2>
      <form className="list-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="list-form__fields-block">
          <div className="list-form__fields-block__field-wrap">
            <Controller
              control={control}
              name="region"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="region" className="label">
                  <Select
                    id="region"
                    className="select"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    showSearch
                    allowClear
                    onClear={clearCurrentRegion}
                    onSearch={e => {
                      onChangeHandler(e);
                    }}
                    ref={ref}
                    placeholder="Stadt wählen"
                    defaultValue={currentRegion && currentRegion}
                    options={REGION_DATA}
                  />
                </label>
              )}
            />
          </div>
        </div>

        <div className="list-form__btn-wrap">
          <input className="btn-submit" type="submit" value="Suchen" />
        </div>
      </form>
    </div>
  );
};
