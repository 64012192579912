import React, { FC } from 'react';

import './Pagination.scss';
import { logo } from '../../../assets/img';

type PaginationPropsType = {
  previous: string | null;
  next: string | null;
  currentPage: number;
  countPage: number;
  previousCallback: () => void;
  nextCallback: () => void;
  // eslint-disable-next-line react/require-default-props
  toFirstList?: () => void;
  // eslint-disable-next-line react/require-default-props
  toLastList?: () => void;
};

export const Pagination: FC<PaginationPropsType> = ({
  previous,
  next,
  countPage,
  currentPage,
  previousCallback,
  nextCallback,
  toFirstList,
  toLastList,
}) => {
  const btnPrevClassName =
    previous === null ? 'btn-pagination-disabled' : 'btn-pagination';
  const btnNextClassName = next === null ? 'btn-pagination-disabled' : 'btn-pagination';
  const btnToFirstClassName =
    currentPage === 1 ? 'btn-pagination-to-first-disabled' : 'btn-pagination-to-first';
  const btnToLastClassName =
    next === null ? 'btn-pagination-to-last-disabled' : 'btn-pagination-to-last';
  const toFirstListHandler = (): void => {
    // eslint-disable-next-line no-unused-expressions
    toFirstList && toFirstList();
  };

  const toLastListHandler = (): void => {
    // eslint-disable-next-line no-unused-expressions
    toLastList && toLastList();
  };

  return (
    <div className="pagination">
      <div className="button-container">
        {toFirstList && (
          <button
            type="button"
            onClick={toFirstListHandler}
            disabled={currentPage === 1}
            className={btnToFirstClassName}
          >
            <img className="to_first-arrow" src={logo.arrow_prev} alt="first" />
            <img src={logo.arrow_prev} alt="first" />
          </button>
        )}

        <button
          type="button"
          className={btnPrevClassName}
          disabled={previous === null}
          onClick={previousCallback}
        >
          <img src={logo.arrow_prev} alt="previous" />
        </button>
        <span className="page-number">
          {currentPage} / {countPage}
        </span>
        <button
          type="button"
          className={btnNextClassName}
          disabled={next === null}
          onClick={nextCallback}
        >
          <img src={logo.arrow_next} alt="next" />
        </button>

        {toLastList && (
          <button
            type="button"
            onClick={toLastListHandler}
            disabled={next === null}
            className={btnToLastClassName}
          >
            <img className="to_last-arrow" src={logo.arrow_next} alt="first" />
            <img src={logo.arrow_next} alt="first" />
          </button>
        )}
      </div>
    </div>
  );
};
