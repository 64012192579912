/* eslint-disable */
// import i18next from 'i18next';

/**
 * "loadToken" is used to get the token from local storage.
 * The function takes nothing and returns a token.
 * */
export const loadToken = () => {
    try {
        const tokenJSON = localStorage.getItem('token');
        if (tokenJSON === null) {
            return undefined;
        }
        return JSON.parse(tokenJSON);
    } catch (err) {
        return undefined;
    }
};

/**
 * "saveToken" is used to save the token to local storage.
 * The function takes a token and returns nothing.
 * @param {string} token - token
 * */
export const saveToken = (token: string) => {
    try {
        const tokenJSON = JSON.stringify(token);
        localStorage.setItem('token', tokenJSON);
    } catch {
        // ignore write errors
    }
};

/**
 * "removeToken" is used to remove the token from local storage.
 * */
export const removeToken = () => {
    try {
        localStorage.removeItem('token');
    } catch {
        // ignore write errors
    }
};

