import { createSlice } from '@reduxjs/toolkit';

import { ILayoutListResponse } from '../api/layout-api';

import { fetchLayout } from './meddleware/layoutMiddleware';

interface ILayoutState {
  layout: ILayoutListResponse;
}

const initialState: ILayoutState = {
  layout: {} as ILayoutListResponse,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchLayout.fulfilled, (state, action) => {
      state.layout = action.payload;
    });
  },
});

export const layoutReducer = layoutSlice.reducer;
