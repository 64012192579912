import { AxiosResponse } from 'axios';

import { instanceAdmin } from './http';

export const profileApi = {
  getProfileData() {
    return instanceAdmin.get<IProfileData, AxiosResponse<IProfileData>>('/profile/');
  },
};

export interface IProfileData {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  is_active: boolean;
  date_joined: string;
  gender: string;
  gender_display: string;
  phone: string;
  sity: string;
  street: string;
  street_number: string;
  zip_code: string;
  company_name: string;
  send_email: boolean;
  tariff: string;
}
