import React, { FC } from 'react';

import './CompanyComponentForList.scss';
import { NavLink } from 'react-router-dom';

import { ICategory } from '../../api/categories-api';
import { ISearchCompany } from '../../api/search-api';
import arrowImg from '../../assets/img/svg/circle-arrow-right-solid.svg';
import locationImg from '../../assets/img/svg/location-dot-solid.svg';
import phoneImg from '../../assets/img/svg/phone-solid.svg';
import { PATH } from '../../routes/Routes';

interface ICompanyComponent {
  company: ISearchCompany;
  currentCategory: ICategory | undefined;
}

export const CompanyComponentForList: FC<ICompanyComponent> = ({
  company,
  currentCategory,
}) => {
  const companyLogo = currentCategory?.icon;

  return (
    <div className="company-item">
      <div className="company-item__wrap">
        <div className="company-item__wrap__logo-block">
          <img
            src={companyLogo}
            alt="company logo"
            className="company-item__wrap__logo-block__logo"
          />
        </div>
        <NavLink
          to={`${PATH.COMPANY_ITEM}${company.slug}/`}
          className="company-item__wrap__content"
        >
          <span className="company-item__wrap__content__title">{company.name}</span>
          <span className="company-item__wrap__content__address">
            <span className="address">
              <img src={locationImg} alt="location" />
              {company.street}, {company.region}
            </span>
            {/* <u>Rinkartstr. 1, Berlin</u> */}
            {company.phone && (
              <span>
                <img src={phoneImg} alt="" />
                {company.phone}
              </span>
            )}
          </span>
        </NavLink>
      </div>
      <NavLink
        to={`${PATH.COMPANY_ITEM}${company.slug}/`}
        className="company-item__more-desc"
      >
        <img src={arrowImg} alt="more description" />
        Mehr
      </NavLink>
    </div>
  );
};
