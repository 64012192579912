import { createAsyncThunk } from '@reduxjs/toolkit';

import { regionsApi } from '../../api/regions-api';

export const fetchRegions = createAsyncThunk(
  'regions/fetchRegions',
  async (regions: string = '', thunkAPI) => {
    try {
      const res = await regionsApi.getRegionsList(regions);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);
