import React, { FC, memo } from 'react';
import './NewsContainer.scss';

interface ITextContainer {}

export const NewsContainer: FC<ITextContainer> = memo(() => (
  <section className="main-news-container">
    <div className="main-news-container__wrap">
      <h2 className="main-news-container__wrap__title">Neuigkeiten</h2>
      <div className="main-news-container__wrap__news-block">
        <div className="main-news-container__wrap__news-block__item">
          <div className="main-news-container__wrap__news-block__item__text">
            <p>1. Dezember 2022</p>
            <h3>Kur und Rehabilitationsmaßnahmen für Pflegende</h3>
            <p>
              Pflegende Angehörige sind hohen Belastungen ausgesetzt. Unter bestimmten
              Voraussetzungen ist auch zum Beispiel eine Kur möglich.
            </p>
          </div>
        </div>

        <div className="main-news-container__wrap__news-block__item">
          <div className="main-news-container__wrap__news-block__item__text">
            <p>28. November 2022</p>
            <h3>
              Pflege aus der Distanz: Zeitliche und räumliche Flexibilität durch Beratung
              per Videogespräch
            </h3>
            <p>
              Die Anzahl der Angehörigen, die sich aus größerer oder großer räumlicher
              Distanz um die Pflege ihrer Angehörigen kümmern, wird zunehmend höher.
              Wissenschaftler gehen in ihren Annahmen von rund einem Viertel aller
              pflegenden Angehörigen aus, auf die diese Beschreibung zutrifft.
            </p>
          </div>
        </div>

        <div className="main-news-container__wrap__news-block__item">
          <div className="main-news-container__wrap__news-block__item__text">
            <p>9. November 2022</p>
            <h3>6 Tipps für die Gesundheit von Pflegenden</h3>
            <p>
              Vielen Pflegenden fällt es nicht leicht, selbst Unterstützung anzunehmen.
              Doch nur, wenn sie umsichtig auch für sich selbst Sorge tragen, erhalten sie
              ihre eigene Lebensqualität und innere Stärke.
            </p>
          </div>
        </div>

        <div className="main-news-container__wrap__news-block__item">
          <div className="main-news-container__wrap__news-block__item__text">
            <p>2. November 2022</p>
            <h3>Flexible Beratung bei Krankenhausaufenthalten</h3>
            <p>
              Die flexible Pflegeberatung ermöglicht eine in Teilen ortsunabhängige
              Beratung und kann dadurch auch Patient und ihre Angehörigen bei der
              Entlassung auf dem Krankenhaus unterstützen.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
));
