import React, { FC, useEffect, useState } from 'react';

import './CompanyDescription.scss';
import { Spin } from 'antd';
import { NavLink, useParams } from 'react-router-dom';

import { IBanner } from '../../api/layout-api';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { PATH } from '../../routes/Routes';
import { fetchCompanyDescription } from '../../store/meddleware/companiesMiddleware';
import { fetchLayout } from '../../store/meddleware/layoutMiddleware';
import { getRandomItem } from '../../utils/getRandomItem';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const CompanyDescription: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const companySlug = params.id;

  const currentCompany = useAppSelector(state => state.companiesReducer?.currentCompany);
  const layoutsList = useAppSelector(state => state.layoutReducer.layout?.results);
  const isLoading = useAppSelector(state => state.appReducer.isLoading);

  const [currentCompanyBanner, setCurrentCompanyBanner] = useState<IBanner>();

  const COMPANY_RIGHTCOL_BIG = layoutsList?.find(
    layout => layout.slug === 'company-rightcol-big',
  );

  useEffect(() => {
    if (companySlug != null) {
      dispatch(fetchCompanyDescription(companySlug));
    }
  }, []);

  useEffect(() => {
    if (!layoutsList) {
      dispatch(fetchLayout());
    }
  }, []);

  useEffect(() => {
    if (COMPANY_RIGHTCOL_BIG) {
      setCurrentCompanyBanner(getRandomItem(COMPANY_RIGHTCOL_BIG?.banners) as IBanner);
    }
  }, [layoutsList]);

  const countCategory =
    currentCompany?.categories_info && currentCompany?.categories_info.length;
  const categoryInfo =
    currentCompany?.categories_info &&
    currentCompany?.categories_info[countCategory - countCategory];

  const companyLogo = currentCompany?.logo
    ? currentCompany?.logo
    : categoryInfo && `${BASE_URL}${categoryInfo.icon}`;

  return (
    <div className="company-page">
      <div className="company-page__wrap">
        <div className="company-page__wrap__desc-block">
          <img
            className="company-page__wrap__desc-block__comp-logo"
            src={companyLogo}
            alt="company logo"
          />
          <div className="company-page__wrap__desc-block__content">
            <h2 className="company-page__wrap__desc-block__content__title">
              KONTAKTDETAILS
            </h2>
            <table>
              <tbody>
                <tr className="tr">
                  <td className="td">Name:</td>
                  <td className="td-cont">{currentCompany?.name}</td>
                </tr>
                <tr className="tr">
                  <td className="td">Adresse:</td>
                  <td className="td-cont">
                    {currentCompany?.country}, {currentCompany?.region},{' '}
                    {currentCompany?.street}
                  </td>
                </tr>
                {currentCompany?.email && (
                  <tr className="tr">
                    <td className="td">E-Mail:</td>
                    <td className="td-cont">{currentCompany?.email}</td>
                  </tr>
                )}
                {currentCompany?.zip && (
                  <tr className="tr">
                    <td className="td">PLZ:</td>
                    <td className="td-cont">{currentCompany?.zip}</td>
                  </tr>
                )}

                {currentCompany?.phone && (
                  <tr className="tr">
                    <td className="td">Phone:</td>
                    <td className="td-cont">{currentCompany?.phone}</td>
                  </tr>
                )}
                {currentCompany?.fax && (
                  <tr className="tr">
                    <td className="td">Fax:</td>
                    <td className="td-cont">{currentCompany?.fax}</td>
                  </tr>
                )}
                {currentCompany?.www && (
                  <tr className="tr">
                    <td className="td">Website:</td>
                    <td className="td-cont">{currentCompany?.www}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <NavLink
              to={`${PATH.ORDER}${currentCompany?.id}/`}
              className="company-page__wrap__desc-block__content__button"
            >
              Vermitteln
            </NavLink>
          </div>

          <Spin
            className={`${isLoading ? 'preloader-active' : 'preloader'}`}
            size="large"
          />
        </div>
        <div className="company-page__wrap__advertising-block">
          <a href={currentCompanyBanner?.url}>
            <img
              className="company-page__wrap__advertising-block__img"
              src={currentCompanyBanner?.image}
              alt={currentCompanyBanner?.title}
            />
          </a>
        </div>
      </div>
    </div>
  );
};
