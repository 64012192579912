import React, { FC, useEffect } from 'react';

import './App.scss';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { Footer } from './pages/Main/Footer/Footer';
import { Header } from './pages/Main/Header/Header';
import { RoutesComponent } from './routes/Routes';
import { verifyTC } from './store/meddleware/authMiddleware';
import { loadToken } from './utils/localStorage';

const App: FC = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(state => state.authReducer.isLoggedIn);
  const token = loadToken();

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     dispatch(verifyTC());
  //   }
  // }, [isLoggedIn]);

  useEffect(() => {
    if (token) {
      dispatch(verifyTC());
    }
  }, [isLoggedIn]);

  return (
    <div className="App">
      <Header />
      <Footer />
      <RoutesComponent />
    </div>
  );
};

export default App;
