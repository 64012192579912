import React, { FC, memo } from 'react';
import './CategoryContainer.scss';

import { ICategory } from '../../../../api/categories-api';
import { ICompaniesResponse } from '../../../../api/companies-api';

import { CategoryItemBlock } from './CategoryItemBlock/CategoryItemBlock';

interface ICategoryContainer {
  categories: ICategory[];
  getCurrentCategoryCompanies: (categoryId: number) => ICompaniesResponse | undefined;
}

export const CategoryContainer: FC<ICategoryContainer> = memo(
  ({ categories, getCurrentCategoryCompanies }) => (
    <section className="category-block">
      <div className="category-block__wrap">
        {categories &&
          categories.map(category => (
            <CategoryItemBlock
              key={category.id}
              categoryId={category.id}
              categories={categories}
              companies={getCurrentCategoryCompanies(category.id)}
            />
          ))}
      </div>
    </section>
  ),
);
