import React, { FC } from 'react';

import './Tariffs.scss';
import { NavLink } from 'react-router-dom';

import { PATH } from '../../routes/Routes';

export const Tariffs: FC = () => {
  const TARIFFS_DATA = [
    {
      id: 1,
      name: 'Basic',
      description: [
        {
          id: 11,
          title: 'keine Monatliche Grundgebühr',
        },
        {
          id: 12,
          title: 'Einmalige Einrichtungsgebühr: 499,-€ zzgl. USt.',
        },
      ],
      price: 'FREE',
    },

    {
      id: 2,
      name: 'Premium',
      description: [
        {
          id: 21,
          title: 'Preis: 699,-€ zzgl. USt./Monat',
        },
        {
          id: 22,
          title: 'Einmalige Einrichtungsgebühr: 499,-€ zzgl. USt.',
        },
      ],
      price: '699€',
    },

    {
      id: 3,
      name: 'Premium Plus',
      description: [
        {
          id: 31,
          title: 'Preis: 999,-€ zzgl. USt./Monat',
        },
        {
          id: 32,
          title: 'Keine Einrichtungsgebühr',
        },
      ],
      price: '999€',
    },
  ];

  const colorTariffClass = (name: string): string => {
    if (name === 'Basic') {
      return 'basicTarif';
    }
    if (name === 'Premium') {
      return 'premiumTarif';
    }
    if (name === 'Premium Plus') {
      return 'premiumPlusTarif';
    }
    return 'basicTarif';
  };

  return (
    <section className="root_tariffs">
      <div className="root_tariffs__wrap">
        <div className="root_tariffs__wrap__tariffs-header">
          <h1 className="root_tariffs__wrap__tariffs-header__title">Unsere Tarife</h1>

          <div className="root_tariffs__wrap__tariffs-header__tariffs">
            {TARIFFS_DATA &&
              TARIFFS_DATA.map(tarif => (
                <div
                  key={tarif.id}
                  className={`root_tariffs__wrap__tariffs-header__tariffs__tariff ${colorTariffClass(
                    tarif.name,
                  )}`}
                >
                  <div className="root_tariffs__wrap__tariffs-header__tariffs__tariff__title">
                    {tarif.name}
                  </div>
                  <div className="root_tariffs__wrap__tariffs-header__tariffs__tariff__middle">
                    <div className="root_tariffs__wrap__tariffs-header__tariffs__tariff__middle__desc">
                      <span>
                        <ul>
                          {tarif.description.map(desc => (
                            <li
                              className={`${colorTariffClass(tarif.name)}_li`}
                              key={desc.id}
                            >
                              {desc.title}
                            </li>
                          ))}
                        </ul>
                      </span>
                    </div>
                  </div>
                  <div className="root_tariffs__wrap__tariffs-header__tariffs__tariff__price">
                    {tarif.price}
                  </div>
                </div>
              ))}
          </div>

          <NavLink
            className="root_tariffs__wrap__tariffs-header__registr-btn"
            to={PATH.SIGN_UP}
          >
            Registrierung
          </NavLink>
        </div>

        <div className="root_tariffs__wrap__tariffs_desc">
          <div className="root_tariffs__wrap__tariffs_desc__title">
            Über 5 Mio. Patienten suchen monatlich einen Pflegedienst auf Pflegeraiting
          </div>
          <div className="root_tariffs__wrap__tariffs_desc__text">
            <p>
              Pflegespot ist Deutschlands größte Portal für Gesundheitswesen. Mit
              monatlich über 5 Millionen Nutzern verzeichnen wir so viele Suchanfragen zum
              Theme Pflegedienste oder ähnliche Einrichtungen wie kein anderer. Kein
              Wunder, denn: Jeder Dritte kennt Pflegeraiting. 28 % der Deutschen nutzten
              schon mindestens einmal Pflegeraiting zur Suche nach dem passenden
              Pflegedienst (online-repräsentative Patientenstudie 2014).
            </p>
            <p>
              Mit Ihrem Premium-Paket lenken Sie die Aufmerksamkeit dieser Patienten auf
              Ihr Pflegeraiting-Profil und gewinnen sie ihn so für Ihre Pflegeeinrichtung.
              Übrigens: Mehr als 20 % der Pflegeraiting-Nutzer sind Privatpatienten. Damit
              ist der Anteil fast doppelt so hoch wie der Bundes­durchschnitt mit 11 %.
            </p>
          </div>
        </div>

        <div className="root_tariffs__wrap__tariffs_desc">
          <div className="root_tariffs__wrap__tariffs_desc__title">
            95% der Premium-Profile listet Google auf Seite 1
          </div>
          <div className="root_tariffs__wrap__tariffs_desc__text">
            <p>
              95% unserer Kunden sind mit ihrem Premium-Profil bei Google auf Seite 1.
              Zudem hat das Premium-Paket positive Auswirkungen auf das Google-Ranking
              Ihrer Praxis-Homepage, da wir mehrfach von Ihrem Pflegeraiting-Profil darauf
              verlinken.
            </p>
            <p>
              So erhalten Sie durch Ihr Premium-Paket nicht nur direkt neue Patienten über
              Pflegeraiting, sondern auch indirekt durch die verbesserte
              Google-Platzierung Ihrer Homepage.
            </p>
          </div>
        </div>

        <div className="root_tariffs__wrap__tariffs_desc">
          <div className="root_tariffs__wrap__tariffs_desc__title">
            Die Pflegeraiting Qualitätsgarantie
          </div>
          <div className="root_tariffs__wrap__tariffs_desc__text">
            Unser Qualitätsteam garantiert allen Pflegediensten – unabhängig davon, ob sie
            Kunden sind oder nicht – einen ver­antwortungs­vollen Umgang mit
            Patientenmeinungen und schützt sie vor Beleidigungen und Schmähkritik.
          </div>
        </div>

        <div className="root_tariffs__wrap__tariffs_desc">
          <div className="root_tariffs__wrap__tariffs_desc__title">
            Ein Premium-Profil weckt hohes Interesse bei Patienten
          </div>
          <div className="root_tariffs__wrap__tariffs_desc__text">
            <p>
              Die Profile der Pflegeraiting Premium-Kunden werden deutlich häufiger von
              Patienten aufgerufen als Profile von Nicht-Kunden. Aufgrund des
              Portraitfotos des Pflegedienstes und der Praxisbilder wecken die
              Premium-Profile auffällig mehr Interesse bei Patienten.
            </p>
            <p>
              Mit der Darstellung Ihrer Schwer­punkte und Ihres Behandlungs­spektrums
              geben Sie Patienten wichtige Informationen über sich und Ihre Praxis. Sie
              können zudem direkt auf Ihre Praxis-Website verlinken. Gleichzeitig blenden
              wir Ihr Premium-Profil auf den Profilen anderer Ärzte in der Umgebung ein,
              die keine Pflegeraiting Kunden sind. So erzielt Ihr Premium-Profil
              zusätzliche Auf­merk­samkeit bei den Patienten.
            </p>
          </div>
        </div>

        <div className="root_tariffs__wrap__tariffs_desc">
          <div className="root_tariffs__wrap__tariffs_desc__title">
            Unsere Premium-Kunden gewinnen durchschnittlich 30 neue Patienten im Jahr
          </div>
          <div className="root_tariffs__wrap__tariffs_desc__text">
            <p>
              70 % der Deutschen suchen ihren Pflegedienst über das Internet
              (repräsentative Patientenstudie 12/2012) – Tendenz steigend. Mit einem
              Premium-Paket auf Pflegeraiting erreichen Sie diese potenziellen Patienten.
            </p>
            <p>
              Unsere Kunden bestätigen: Mit einem Premium-Paket bei Pflegeraiting gewinnen
              Sie jährlich im Durchschnitt 10 neue Privatpatienten und 20 neue gesetzlich
              Versicherte.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
