import { AxiosResponse } from 'axios';

import { ResponseType } from './banner-api';
import { instanceAdmin } from './http';

export const institutionApi = {
  getInstitutionList() {
    return instanceAdmin.get<
      IInstitutionResponseData,
      AxiosResponse<IInstitutionResponseData>
    >('api/institution/');
  },
  getInstitution(institutionId: number) {
    return instanceAdmin.get<IProfileInstitution, AxiosResponse<IProfileInstitution>>(
      `api/institution/${institutionId}/`,
    );
  },
  createInstitution(institutionData: IInstitutionFormData | any) {
    // const formData = new FormData();
    //
    // formData.append('categories', institutionData.categories);
    // formData.append('logo', institutionData.logo);
    // formData.append('cover', institutionData.cover);
    // formData.append('name', institutionData.name);
    // formData.append('slug', institutionData.slug);
    // formData.append('description', institutionData.description);
    // formData.append('post_status', institutionData.post_status);
    // formData.append('www', institutionData.www);
    // formData.append('email', institutionData.email);
    // formData.append('fax', institutionData.fax);
    // formData.append('phone', institutionData.phone);
    // formData.append('country', institutionData.country);
    // formData.append('region', institutionData.region);
    // formData.append('street', institutionData.street);
    // formData.append('zip', institutionData.zip);
    // formData.append('lat', institutionData.lat);
    // formData.append('lng', institutionData.lng);

    return instanceAdmin.post<IInstitutionFormData, AxiosResponse<IProfileInstitution>>(
      'api/institution/',
      institutionData,
    );
  },
  editInstitution(payload: IPayloadEditInstitutionData) {
    // const formData = new FormData();
    // formData.append('categories', payload.institutionData.categories);
    // if (payload.institutionData.logo) {
    //   formData.append('logo', payload.institutionData.logo);
    // }
    // if (payload.institutionData.cover) {
    //   formData.append('cover', payload.institutionData.cover);
    // }
    // formData.append('name', payload.institutionData.name);
    // formData.append('slug', payload.institutionData.slug);
    // formData.append('description', payload.institutionData.description);
    // formData.append('post_status', payload.institutionData.post_status);
    // formData.append('www', payload.institutionData.www);
    // formData.append('email', payload.institutionData.email);
    // formData.append('fax', payload.institutionData.fax);
    // formData.append('phone', payload.institutionData.phone);
    // formData.append('country', payload.institutionData.country);
    // formData.append('region', payload.institutionData.region);
    // formData.append('street', payload.institutionData.street);
    // formData.append('zip', payload.institutionData.zip);
    // formData.append('lat', payload.institutionData.lat);
    // formData.append('lng', payload.institutionData.lng);

    return instanceAdmin.patch<IInstitutionFormData, AxiosResponse<IProfileInstitution>>(
      `api/institution/${payload.institutionId}/`,
      payload.institutionData,
    );
  },
  removeInstitution(institutionId: number) {
    return instanceAdmin.delete<IProfileInstitution, AxiosResponse<ResponseType>>(
      `api/institution/${institutionId}/`,
    );
  },
};

export interface IPayloadEditInstitutionData {
  institutionId: number;
  institutionData: IInstitutionFormData | any;
}

interface ICategoryInfo {
  created_at: string;
  icon: string;
  id: number;
  marker: string;
  name: string;
  slug: string;
  updated_at: string;
}

export interface IProfileInstitution {
  id: number;
  categories: number[];
  categories_info: ICategoryInfo[];
  // logo: string;
  // cover: string;
  name: string;
  slug: string;
  description: string;
  post_status: string;
  www: string;
  email: string;
  fax: string;
  phone: string;
  country: string;
  region: string;
  street: string;
  zip: string;
  lat: string;
  lng: string;
  created_at: string;
}

export interface IInstitutionResponseData {
  count: number;
  links: {
    next: string | null;
    prev: string | null;
  };
  num_pages: number;
  current_page: number;
  results: IProfileInstitution[];
}

export interface IInstitutionFormData {
  categories: number[];
  // logo: string;
  // cover: string;
  name: string;
  slug: string;
  description: string;
  post_status: string;
  www: string;
  email: string;
  fax: string;
  phone: string;
  country: string;
  region: string;
  street: string;
  zip: string;
  lat: string;
  lng: string;
}

export const INSTITUTION_FORM_DATA = {
  categories: {
    title: 'Categories',
    options: [] as { value: number; label: string }[],
  },
  // logo: {
  //   title: 'Image',
  // },
  // cover: {
  //   title: 'Cover',
  // },
  name: {
    title: 'Name',
  },
  slug: {
    title: 'Slug',
  },
  description: {
    title: 'Description',
  },
  post_status: {
    title: 'Post status',
  },
  www: {
    title: 'WWW',
  },
  email: {
    title: 'Email',
  },
  fax: {
    title: 'Fax',
  },
  phone: {
    title: 'Phone',
  },
  country: {
    title: 'Country',
  },
  region: {
    title: 'Region',
  },
  street: {
    title: 'Street',
  },
  zip: {
    title: 'Zip',
  },
  lat: {
    title: 'Lat',
  },
  lng: {
    title: 'Lang',
  },
};
