import React, { FC, useEffect, useState } from 'react';

import { Modal } from 'antd';
import { NavLink } from 'react-router-dom';

import picturesImg from '../../../assets/img/svg/image-regular.svg';
import editImg from '../../../assets/img/svg/pen-to-square-solid.svg';
import removeImg from '../../../assets/img/svg/trash-can-solid.svg';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { PATH } from '../../../routes/Routes';
import {
  fetchProfileBannersTC,
  removeBannerTC,
} from '../../../store/meddleware/bannerMiddleware';

import './ProfileBanners.scss';

interface IProfileBanners {}

export const ProfileBanners: FC<IProfileBanners> = () => {
  const dispatch = useAppDispatch();

  const banners = useAppSelector(state => state.bannerReducer.banners);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [selectedBanner, setSelectedBanner] = useState<number | null>(null);

  useEffect(() => {
    dispatch(fetchProfileBannersTC());
  }, []);

  const removeBanner = (bannerId: number): void => {
    dispatch(removeBannerTC(bannerId));
    // console.log(bannerId);
    setSelectedBanner(null);
    setIsOpenModal(false);
  };

  const changeModalState = (): void => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <div className="admin-page__banners">
      <table className="admin-page__banners__table">
        <thead className="admin-page__banners__table__thead">
          <tr className="admin-page__banners__table__thead__tr">
            <th>Banner layot</th>
            <th>Banner title</th>
            <th>Banner created at</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody className="admin-page__banners__table__tbody">
          {banners &&
            banners.results?.map(banner => (
              <tr className="admin-page__banners__table__tbody__tr" key={banner.id}>
                <td className="td layout-info">
                  {banner.layout_info.title} [{banner.layout_info.width}x
                  {banner.layout_info.height}]
                </td>
                <td className="td">{banner.title}</td>
                <td className="td">{banner.created_at}</td>
                <td className="td td-option">
                  <a
                    className="picture-link"
                    href={banner.image}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={picturesImg} alt="bannerData" />
                  </a>
                  <NavLink
                    to={`${PATH.BANNER_FORM}${banner.id}/`}
                    className="edit-link"
                    role="presentation"
                  >
                    <img src={editImg} alt="delete" />
                  </NavLink>
                  <span
                    className="remove-link"
                    role="presentation"
                    onClick={() => {
                      changeModalState();
                      setSelectedBanner(banner.id);
                    }}
                  >
                    <img src={removeImg} alt="delete" />
                  </span>
                </td>
                <Modal
                  title="Banner entfernen?"
                  open={isOpenModal}
                  onOk={() => {
                    if (selectedBanner) {
                      removeBanner(selectedBanner);
                    }
                  }}
                  onCancel={changeModalState}
                />
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
