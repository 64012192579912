import React, { FC, useEffect, useMemo, useState } from 'react';
import './InstitutionForm.scss';

// import { UploadOutlined } from '@ant-design/icons';
import { /* Button,  */ Input, Select /* Upload, UploadFile */ } from 'antd';
// import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';

import {
  IInstitutionFormData,
  INSTITUTION_FORM_DATA,
} from '../../../../api/institution-api';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { PATH } from '../../../../routes/Routes';
import { setAppErrorAC } from '../../../../store/AppSlice';
import { fetchCategories } from '../../../../store/meddleware/categoriesMiddleware';
import {
  createInstitutionTC,
  editInstitutionTC,
  fetchProfileInstitutionTC,
} from '../../../../store/meddleware/institutionMiddleware';

export const InstitutionForm: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const isLoggedIn = useAppSelector(
    (state: { authReducer: { isLoggedIn: any } }) => state.authReducer.isLoggedIn,
  );
  const error = useAppSelector(state => state.appReducer.error);
  const categories = useAppSelector(state => state.categoriesReducer.categories?.results);
  const institutions = useAppSelector(
    state => state.institutionReducer.institutions?.results,
  );
  const currInstitutionFromState = useAppSelector(
    state => state.institutionReducer.currentInstitution,
  );

  // const [currLogo, setCurrLogo] = useState<any>(null);
  // const [isChosenLogo, setIsChosenLogo] = useState<boolean>(false);
  // const [currCover, setCurrCover] = useState<any>(null);
  // const [isChosenCover, setIsChosenCover] = useState<boolean>(false);
  const [isSendData, setIsSendData] = useState<boolean>(false);

  const idUrl = params.id;
  const institutionId = Number(idUrl);
  const institutionFromList =
    institutions && institutions?.find(res => res.id === institutionId);

  const currentInstitution = institutionFromList || currInstitutionFromState;

  INSTITUTION_FORM_DATA.categories.options = categories?.map(cat => ({
    value: cat.id,
    label: cat.name,
  }));

  const defaultInstitutionData =
    currentInstitution &&
    ({
      categories: currentInstitution.categories,
      name: currentInstitution.name,
      slug: currentInstitution.slug,
      description: currentInstitution.description,
      post_status: currentInstitution.post_status,
      www: currentInstitution.www,
      email: currentInstitution.email,
      fax: currentInstitution.fax,
      phone: currentInstitution.phone,
      country: currentInstitution.country,
      region: currentInstitution.region,
      street: currentInstitution.street,
      zip: currentInstitution.zip,
      lat: currentInstitution.lat,
      lng: currentInstitution.lng,
    } as IInstitutionFormData);

  const { handleSubmit, control, reset } = useForm<IInstitutionFormData>({
    // eslint-disable-next-line consistent-return
    defaultValues: useMemo(() => {
      if (defaultInstitutionData) {
        return defaultInstitutionData;
      }
    }, [defaultInstitutionData]),
  });

  useEffect(() => {
    if (!categories) {
      dispatch(fetchCategories());
    }
  }, []);

  useEffect(() => {
    if (defaultInstitutionData) {
      reset(defaultInstitutionData);
    }
  }, [currentInstitution]);

  useEffect(() => {
    if (!institutionFromList && institutionId) {
      dispatch(fetchProfileInstitutionTC(institutionId));
    }
  }, []);

  // useEffect(() => {
  //   setCurrLogo(currLogo);
  //   setIsChosenLogo(false);
  // }, [isChosenLogo]);

  // useEffect(() => {
  //   setCurrCover(currCover);
  //   setIsChosenCover(false);
  // }, [isChosenCover]);

  useEffect(
    () => () => {
      dispatch(setAppErrorAC({ error: null }));
      setIsSendData(false);
    },
    [],
  );

  // const onRemoveLogo = (): void => {
  //   setCurrLogo(null);
  // };

  // const onRemoveCover = (): void => {
  //   setCurrCover(null);
  // };

  // const onChangeUploadLogo = (info: UploadChangeParam<UploadFile<any>>): void => {
  //   setIsChosenLogo(true);
  //   setCurrLogo(info.fileList[0].originFileObj);
  // };

  // const onChangeUploadCover = (info: UploadChangeParam<UploadFile<any>>): void => {
  //   setIsChosenCover(true);
  //   setCurrCover(info.fileList[0].originFileObj);
  // };
  //
  // const beforeUpload = (file: RcFile): Promise<void> =>
  //   new Promise(resolve => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       const img = document.createElement('img');
  //       img.src = reader.result as string;
  //       img.onload = () => {
  //         const canvas = document.createElement('canvas');
  //         canvas.width = img.naturalWidth;
  //         canvas.height = img.naturalHeight;
  //         const ctx = canvas.getContext('2d')!;
  //         ctx.drawImage(img, 0, 0);
  //         canvas.toBlob(result => resolve(result as any));
  //       };
  //     };
  //   });

  const onSubmit: SubmitHandler<IInstitutionFormData> = async data => {
    // const newInstitution = {
    //   categories: data.categories,
    //   // logo: currLogo && currLogo,
    //   // cover: currCover && currCover,
    //   name: data.name,
    //   slug: data.slug,
    //   description: data.description,
    //   post_status: data.post_status,
    //   www: data.www,
    //   email: data.email,
    //   fax: data.fax,
    //   phone: data.phone,
    //   country: data.country,
    //   region: data.region,
    //   street: data.street,
    //   zip: data.zip,
    //   lat: data.lat,
    //   lng: data.lng,
    // };
    if (!currentInstitution) {
      await dispatch(createInstitutionTC(data));
    }
    if (currentInstitution) {
      await dispatch(
        editInstitutionTC({
          institutionId: currentInstitution.id,
          institutionData: data,
        }),
      );
    }
    setIsSendData(true);
  };

  if (!isLoggedIn) {
    return <Navigate to={PATH.LOGIN} />;
  }
  if (!error && isSendData) {
    return <Navigate to={PATH.PROFILE_COMPANIES} />;
  }

  return (
    <div className="institution-form-wrap">
      <form className="institution-form-wrap__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="institution-form-wrap__form__fields-block">
          <div className="institution-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="categories"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="categories" className="label">
                  Categories
                  <Select
                    id="categories"
                    className="select"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    mode="multiple"
                    options={INSTITUTION_FORM_DATA.categories.options}
                  />
                </label>
              )}
            />
          </div>

          {/* <div className="institution-form-wrap__form__fields-block__field-wrap upload-wrap"> */}
          {/*   <Controller */}
          {/*     control={control} */}
          {/*     name="logo" */}
          {/*     render={() => ( */}
          {/*       <label htmlFor="logo" className="label"> */}
          {/*         Logo */}
          {/*         <Upload */}
          {/*           id="logo" */}
          {/*           className="upload" */}
          {/*           listType="picture" */}
          {/*           onChange={onChangeUploadLogo} */}
          {/*           beforeUpload={beforeUpload} */}
          {/*           onRemove={onRemoveLogo} */}
          {/*         > */}
          {/*           {!currLogo && ( */}
          {/*             <Button icon={<UploadOutlined />}>Click to Upload</Button> */}
          {/*           )} */}
          {/*         </Upload> */}
          {/*       </label> */}
          {/*     )} */}
          {/*   /> */}
          {/* </div> */}

          {/* <div className="institution-form-wrap__form__fields-block__field-wrap upload-wrap"> */}
          {/*   <Controller */}
          {/*     control={control} */}
          {/*     name="cover" */}
          {/*     render={() => ( */}
          {/*       <label htmlFor="cover" className="label"> */}
          {/*         Cover */}
          {/*         <Upload */}
          {/*           id="cover" */}
          {/*           className="upload" */}
          {/*           listType="picture" */}
          {/*           onChange={onChangeUploadCover} */}
          {/*           beforeUpload={beforeUpload} */}
          {/*           onRemove={onRemoveCover} */}
          {/*         > */}
          {/*           {!currCover && ( */}
          {/*             <Button icon={<UploadOutlined />}>Click to Upload</Button> */}
          {/*           )} */}
          {/*         </Upload> */}
          {/*       </label> */}
          {/*     )} */}
          {/*   /> */}
          {/* </div> */}

          <div className="institution-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="name" className="label">
                  Name
                  <Input
                    id="name"
                    className="field"
                    defaultValue={currentInstitution ? currentInstitution.name : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="institution-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="slug"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="slug" className="label">
                  Slug
                  <Input
                    id="slug"
                    className="field"
                    defaultValue={currentInstitution ? currentInstitution.slug : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="institution-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="description" className="label">
                  Description
                  <Input
                    id="description"
                    className="field"
                    defaultValue={
                      currentInstitution ? currentInstitution.description : ''
                    }
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="institution-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="post_status"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="post_status" className="label">
                  Post status
                  <Input
                    id="post_status"
                    className="field"
                    defaultValue={
                      currentInstitution ? currentInstitution.post_status : ''
                    }
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="institution-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="www"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="www" className="label">
                  WWW
                  <Input
                    id="www"
                    className="field"
                    defaultValue={currentInstitution ? currentInstitution.www : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="institution-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="email" className="label">
                  Email
                  <Input
                    id="email"
                    className="field"
                    defaultValue={currentInstitution ? currentInstitution.email : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="institution-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="fax"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="fax" className="label">
                  Fax
                  <Input
                    id="fax"
                    className="field"
                    defaultValue={currentInstitution ? currentInstitution.fax : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="institution-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="phone" className="label">
                  Phone
                  <Input
                    id="phone"
                    className="field"
                    defaultValue={currentInstitution ? currentInstitution.phone : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="institution-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="country"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="country" className="label">
                  Country
                  <Input
                    id="country"
                    className="field"
                    defaultValue={currentInstitution ? currentInstitution.country : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="institution-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="region"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="region" className="label">
                  Region
                  <Input
                    id="region"
                    className="field"
                    defaultValue={currentInstitution ? currentInstitution.region : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="institution-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="street"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="street" className="label">
                  Street
                  <Input
                    id="street"
                    className="field"
                    defaultValue={currentInstitution ? currentInstitution.street : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="institution-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="zip"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="zip" className="label">
                  Zip
                  <Input
                    id="zip"
                    className="field"
                    defaultValue={currentInstitution ? currentInstitution.zip : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="institution-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="lat"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="lat" className="label">
                  Lat
                  <Input
                    id="lat"
                    className="field"
                    defaultValue={currentInstitution ? currentInstitution.lat : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>

          <div className="institution-form-wrap__form__fields-block__field-wrap">
            <Controller
              control={control}
              name="lng"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <label htmlFor="lng" className="label">
                  Lng
                  <Input
                    id="lng"
                    className="field"
                    defaultValue={currentInstitution ? currentInstitution.lng : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                </label>
              )}
            />
          </div>
        </div>

        <div className="banner-form-wrap__form__btn-wrap">
          <input className="btn-submit" type="submit" value="Sichern" />
        </div>
      </form>
    </div>
  );
};
