import { createSlice } from '@reduxjs/toolkit';

import { ICategoriesResponse } from '../api/categories-api';

import { fetchCategories } from './meddleware/categoriesMiddleware';

interface ICategoriesState {
  categories: ICategoriesResponse;
}

const initialState: ICategoriesState = {
  categories: {} as ICategoriesResponse,
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    });
  },
});

export const categoriesReducer = categoriesSlice.reducer;
