import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const authAPI = createApi({
  reducerPath: 'postAPI',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  tagTypes: ['Auth'],
  endpoints: build => ({
    login: build.mutation<ILoginResponse, ILoginParams>({
      query: data => ({
        url: `/token/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Auth'],
    }),
    me: build.mutation<IMeResponse, IMeParams>({
      query: (token: IMeParams) => ({
        url: `/token/verify/`,
        method: 'POST',
        body: token,
      }),
      invalidatesTags: ['Auth'],
    }),
    refresh: build.mutation<IAccessParams, IRefreshParams>({
      query: post => ({
        url: `/token/refresh/`,
        method: 'POST',
        body: post,
      }),
      invalidatesTags: ['Auth'],
    }),
    registration: build.mutation<IRegistrationData, IRegistrationData>({
      query: data => ({
        url: `singup/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Auth'],
    }),
  }),
});

export interface ILoginParams {
  username: string;
  password: string;
}

export interface ILoginResponse {
  refresh: string;
  access: string;
  first_name: string;
  last_name: string;
  username: string | null;
  photo: string;
  role: string;
}

export interface IMeParams {
  token: string;
}

export interface IMeResponse {
  detail: string;
  code: string;
}

export interface IRefreshParams {
  refresh: string;
}

export interface IAccessParams {
  access: string;
}

export interface IRegistrationData {
  username: string;
  email: string;
  phone: string;
  gender: string;
  first_name: string;
  last_name: string;
  company_name: string;
  tariff: string;
  send_email: string;
  sity: string;
  street: string;
  street_number: string;
  zip_code: string;
  password: string;
  password_repeat: string;
}
