import { AxiosResponse } from 'axios';

import {
  IAccessParams,
  ILoginParams,
  ILoginResponse,
  IMeParams,
  IMeResponse,
  IRefreshParams,
  IRegistrationData,
} from './authService';
import { instance } from './http';

export const authAPI = {
  login(data: ILoginParams) {
    return instance.post<ILoginParams, AxiosResponse<ILoginResponse>>('/token/', data);
  },
  verify(token: IMeParams) {
    return instance.post<IMeParams, AxiosResponse<IMeResponse>>('/token/verify/', token);
  },
  refresh(refresh: IRefreshParams) {
    return instance.post<IAccessParams>('/token/refresh/', refresh);
  },
  registration(data: IRegistrationData) {
    return instance.post<IRegistrationData, AxiosResponse<IRegistrationData>>(
      'singup/',
      data,
    );
  },
};

export const REGISTRATION_FORM_DATA = {
  username: {
    title: 'Username',
    pattern: '^[-a-zA-Z0-9_]+$',
    minLength: 1,
  },
  email: {
    title: 'E-Mail-Adresse',
    minLength: 1,
  },
  phone: {
    title: 'Telefon',
    maxLength: 20,
    minLength: 1,
  },
  gender: {
    title: 'Anrede',
    options: [
      {
        value: 'Male',
        label: 'Male',
      },
      {
        value: 'Female',
        label: 'Female',
      },
      {
        value: 'Miscellaneous',
        label: 'Miscellaneous ',
      },
    ],
  },
  first_name: {
    title: 'Vorname',
    maxLength: 150,
  },
  last_name: {
    title: 'Nachname',
    maxLength: 150,
  },
  company_name: {
    title: 'Company name',
    maxLength: 100,
    minLength: 1,
  },
  tariff: {
    title: 'Tarif',
    options: [
      {
        value: 'free',
        label: 'free',
      },
      {
        value: '699',
        label: '699',
      },
      {
        value: '999',
        label: '999 ',
      },
    ],
  },
  send_email: {
    title: 'Newsletter',
  },
  city: {
    title: 'Stadt',
    maxLength: 100,
    minLength: 1,
  },
  street: {
    title: 'Straße',
    maxLength: 100,
    minLength: 1,
  },
  street_number: {
    title: 'Nummer',
    maxLength: 200,
    minLength: 1,
  },
  zip_code: {
    title: 'PLZ',
    maxLength: 10,
    minLength: 1,
  },
  password: {
    title: 'Passwort',
    minLength: 1,
  },
  password_repeat: {
    title: 'Passwort (wiederholen)',
    minLength: 1,
  },
};
