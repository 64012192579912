import { AxiosResponse } from 'axios';

import { ICategoriesResponse } from './categories-api';
import { instance } from './http';

export const companiesApi = {
  getCompaniesList(categoryId: string) {
    return instance.get<ICompaniesResponse, AxiosResponse<ICompaniesResponse>>(
      `api/company/new/${categoryId}/`,
    );
  },
  getCompanyDescription(slug: string) {
    return instance.get<ICategoriesResponse, AxiosResponse<ICompany>>(
      `api/company/${slug}/`,
    );
  },
};

export interface ICategoryInfo {
  id: number;
  name: string;
  slug: string;
  marker: string;
  icon: string;
  created_at: string;
  updated_at: string;
}

export interface ICompany {
  id: number;
  categories: number[];
  categories_info: ICategoryInfo[];
  logo: string;
  cover: any | null;
  name: string;
  slug: string;
  description: string;
  post_status: string;
  www: string;
  email: string;
  fax: string;
  phone: string;
  country: string;
  region: string;
  street: string;
  zip: string;
  lat: string;
  lng: string;
  created_at: string;
}

export interface ICompaniesResponse {
  count: number;
  links: {
    next: string | null;
    prev: string | null;
  };
  num_pages: number;
  current_page: number;
  results: ICompany[];
}
