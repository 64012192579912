import { AxiosResponse } from 'axios';

import { instanceAdmin } from './http';

export const bannerApi = {
  getBannersList() {
    return instanceAdmin.get<IBannerResponseData, AxiosResponse<IBannerResponseData>>(
      'api/banner/',
    );
  },
  getBanner(bannerId: number) {
    return instanceAdmin.get<IProfileBanner, AxiosResponse<IProfileBanner>>(
      `api/banner/${bannerId}`,
    );
  },
  createBanner(bannerData: IBannerFormData | any) {
    const formData = new FormData();
    formData.append('image', bannerData.image);
    formData.append('title', bannerData.title);
    formData.append('layout', bannerData.layout);
    formData.append('url', bannerData.url);
    formData.append('description', bannerData.description);
    formData.append('public', bannerData.public);

    return instanceAdmin.post<IBannerFormData, AxiosResponse<IProfileBanner>>(
      'api/banner/',
      formData,
    );
  },
  editBanner(payload: IPayloadEditBannerData) {
    const formData = new FormData();
    if (payload.bannerData.image) {
      formData.append('image', payload.bannerData.image);
    }
    formData.append('title', payload.bannerData.title);
    formData.append('layout', payload.bannerData.layout);
    formData.append('url', payload.bannerData.url);
    formData.append('description', payload.bannerData.description);
    formData.append('public', payload.bannerData.public);

    return instanceAdmin.patch<IBannerEditFormData, AxiosResponse<IProfileBanner>>(
      `api/banner/${payload.bannerId}/`,
      formData,
    );
  },
  removeBanner(bannerId: number) {
    return instanceAdmin.delete<IBannerResponseData, AxiosResponse<ResponseType>>(
      `api/banner/${bannerId}/`,
    );
  },
};

export interface IPayloadEditBannerData {
  bannerId: number;
  bannerData: IBannerEditFormData | any;
}

export interface IProfileBanner {
  id: number;
  title: string;
  image: string;
  layout: number;
  layout_info: {
    height: string;
    id: number;
    slug: string;
    title: string;
    width: string;
  };
  url: string;
  description: string;
  public: boolean;
  created_at: string;
}

export interface IBannerResponseData {
  count: number;
  links: {
    next: string | null;
    prev: string | null;
  };
  num_pages: number;
  current_page: number;
  results: IProfileBanner[];
}

export interface IBannerFormData {
  title: string;
  image: File | null | string;
  layout: number;
  url: string;
  description: string;
  public: boolean;
}

export interface IBannerEditFormData {
  title: string;
  layout: number;
  url: string;
  description: string;
  public: boolean;
  image?: File | null | string;
}

export type ResponseType<D = {}> = {
  config: Object;
  data: D;
  headers: Object;
  request: Object;
  status: number;
  statusText: string;
};

export const BANNER_FORM_DATA = {
  title: {
    title: 'Title',
    minLength: 1,
    maxLength: 256,
  },
  image: {
    title: 'Image',
  },
  layout: {
    title: 'Layout',
    options: [] as { value: number; label: string }[],
  },
  url: {
    title: 'Url',
    maxLength: 200,
  },
  description: {
    title: 'Description',
    // maxLength: 200,
  },
  public: {
    title: 'Public',
  },
};
